/* eslint-disable react/no-unescaped-entities */
import { Card } from 'src/components/atoms/Card';
import Gap from 'src/components/atoms/Gap';
import Section from 'src/components/atoms/Section/Section';
import Text, { TextAlign, TextVariant } from 'src/components/atoms/Text';

export const WelcomePage: React.FC = () => {
  return (
    <Section>
      <Card background="white" elevation={1.5} rounded style={{ maxWidth: 600, margin: 'auto' }}>
        <Text variant={TextVariant.h4} align={TextAlign.center} isBold>
          Document Upload
        </Text>
        <Gap height={1} />
        <Text>
          Sorry, the link you used appears to be invalid. To submit documents for a rental
          application, copy the link you were given into your browser's URL bar and try again.
        </Text>
      </Card>
    </Section>
  );
};
