/* eslint-disable react/no-unescaped-entities */
import { Card } from 'src/components/atoms/Card';
import Gap from 'src/components/atoms/Gap';
import ListCounterContainer from 'src/components/atoms/ListCounterContainer';
import Section from 'src/components/atoms/Section/Section';
import Text, { TextAlign, TextVariant } from 'src/components/atoms/Text';

export const PartnerTermsPage: React.FC = () => {
  return (
    <Section>
      <Card background="white" elevation={1.5} rounded>
        <ListCounterContainer>
          <Text variant={TextVariant.h4} align={TextAlign.center} isBold>
            Terms of Service
          </Text>
          <Text align={TextAlign.center}>
            Effective Date:&nbsp;
            <Text isBold>August 17, 2022</Text>
          </Text>
          <Gap height={1} />
          <Text align={TextAlign.justify}>
            PLEASE READ THIS AGREEMENT CAREFULLY. IT SETS FORTH THE LEGALLY BINDING TERMS AND
            CONDITIONS FOR YOUR USE OF THE SERVICE(S) (DEFINED BELOW) MADE AVAILABLE BY SNAPPT, INC.
            (HEREINAFTER "SNAPPT").
          </Text>
          <Gap height={1} />
          <Text align={TextAlign.justify} isStretched>
            THESE TERMS AND CONDITIONS ("AGREEMENT") ARE A LEGAL AGREEMENT BETWEEN YOU (“CLIENT”,
            "YOU" or "YOUR") AND SNAPPT, THAT SETS FORTH THE LEGAL TERMS AND CONDITIONS FOR YOUR USE
            OF&nbsp;
            <a href="https://snappt.com/" target="_blank" rel="noopener noreferrer">
              WWW.SNAPPT.COM
            </a>
            &nbsp;AND ANY OTHER WEBSITE OWNED AND OPERATED BY SNAPPT (THE "WEBSITE(S)") AND SNAPPT'S
            SERVICES, INCLUDING ANY SOFTWARE, PRODUCTS OR OTHER SERVICES OFFERED BY SNAPPT FROM TIME
            TO TIME AND OTHER SERVICES OFFERED THROUGH THIRD PARTIES INTEGRATING SNAPPT
            FUNCTIONALITY THAT PROVIDE A LINK TO THIS AGREEMENT (COLLECTIVELY, WITH THE WEBSITE(S),
            THE "SERVICE(S)”).
          </Text>
          <Gap height={1} />
          <ol>
            <li>
              <Text isStretched>
                <b>ACCESS TO AND USE OF MANAGED SERVICE</b>
              </Text>
              <Gap height={1} />

              <ol>
                <li>
                  <Text align={TextAlign.justify} isStretched>
                    <b>Scope of License.</b> Snappt provides an online platform that allows
                    businesses, and applicants seeking to patronize such businesses (“Applicants”),
                    to use Snappt’s Websites and Services to facilitate aspects of the application
                    process. Client’s use of the Service(s) is expressly subject to the terms of
                    this Agreement and the agreement (“MSA”) between Client and the applicable
                    reseller/distributor (“Reseller”).
                  </Text>
                </li>
                <li>
                  <Text align={TextAlign.justify} isStretched>
                    <b>Restrictions on Use.</b> Without limiting any restrictions set forth in the
                    MSA, Client agrees that it and its authorized users (“Authorized Users”) shall
                    not: (a) use the Services in any manner or for any purpose other than as
                    permitted by this Agreement or any Documentation; (b) use the Services in
                    violation of any applicable laws (including without limitation the FCRA); (c)
                    sell, lend, rent, resell, lease, sublicense or otherwise transfer any of the
                    rights granted to Client hereunder or pursuant to the MSA to any third party;
                    (d) modify, alter, tamper with, repair or otherwise create derivative works of
                    any software included in or used to provide the Services; (e) reverse engineer,
                    disassemble or decompile the Services or any software contained therein, or
                    attempt to discover or recreate the source code to any Services; (f) remove,
                    obscure or alter any proprietary rights notices related to the Services; (g)
                    access or use the Services in a way intended to avoid incurring fees or exceed
                    usage limits or quotas; (h) access or use the Services to develop or otherwise
                    in furtherance of a competing service; or (i) use the Services to: (i) send
                    unauthorized commercial communications or messages; (ii) store or transmit any
                    file or Client Data containing: (1) unlawful, defamatory, threatening,
                    pornographic, abusive, libelous or otherwise objectionable material of any kind
                    or nature, (2) any material that encourages conduct that could constitute a
                    criminal offense, (3) any code or material that violates any law or regulation,
                    or (4) any code or material that violates the intellectual property rights or
                    rights to the publicity or privacy of others; (iii) transmit any Client Data or
                    materials that contain software viruses or other harmful or deleterious computer
                    code, files or programs such as Trojan horses, worms, time bombs or cancelbots;
                    (iv) interfere with or disrupt servers or networks that provide or support the
                    Services or other Snappt clients’ access to or use of the same; (v) access or
                    attempt to access Snappt’s other accounts, computer systems or networks not
                    covered by this Agreement, through password mining or any other means; or (vi)
                    cause, as determined in Snappt’s sole discretion, an inordinate burden on
                    Snappt’s system resources or capacity. Client shall be liable for the acts or
                    omissions of its Authorized Users in connection with the Services.
                  </Text>
                </li>
                <li>
                  <Text align={TextAlign.justify} isStretched>
                    <b>Suspension.</b> Snappt reserves the right to temporarily suspend or disable
                    Client’s or an Authorized User’s access to or use of the Services in the
                    following circumstances: (a) if Snappt reasonably believes that any use of the
                    Services represents a direct or indirect threat to Snappt’s Services, network
                    function or integrity; (b) if reasonably necessary to prevent unauthorized
                    access to or harm to Client Data or data of other Snappt clients; (c) if Snappt
                    reasonably believes that Client’s use of the Services is in violation of any
                    Laws; or (d) to the extent reasonably necessary to comply with Law. Any
                    suspension pursuant to this section will only be in effect for as long as
                    reasonably necessary to address the issues giving rise to the suspension.
                  </Text>
                </li>
                <li>
                  <Text align={TextAlign.justify} isStretched>
                    <b>Third Party Services.</b> If and to the extent Client uses any products
                    and/or services that are accessed by or made available to Client pursuant to or
                    in connection with this Agreement under the brand name of a third party (“Third
                    Party Service”), Client expressly acknowledges and agrees that, notwithstanding
                    anything herein to the contrary, Snappt shall have no liability whatsoever
                    arising out of or relating to the Third Party Service or the interoperability
                    with the Service.
                  </Text>
                </li>
                <li>
                  <Text align={TextAlign.justify} isStretched>
                    <b>FCRA Certification and User Obligations.</b> Client acknowledges and agrees
                    that each report relating to an Applicant generated by the Services and returned
                    to Client’s Authorized Users (a “Report”) is a “consumer report” and, based on
                    the generation of Reports, Snappt is a “consumer reporting agency,” as those
                    terms are defined in the Fair Credit Reporting Act of 1970, as amended and
                    Regulation V issued thereunder (collectively, “FCRA”).
                  </Text>
                  <Gap height={1} />
                  <ol>
                    <li>
                      <Text align={TextAlign.justify} isStretched>
                        <b>Certification.</b> By using the Service, Client certifies that Client
                        will obtain and use Reports only in connection with Client’s review of
                        applications submitted by Applicants to Client and for no other purpose.
                      </Text>
                    </li>
                    <li>
                      <Text align={TextAlign.justify} isStretched>
                        <b>User Obligations.</b> Client acknowledges that the notice has been
                        provided to Client, and Client agrees to comply with all requirements of the
                        FCRA as from time to time in effect in connection with Client’s obtaining
                        and use of Reports.
                      </Text>
                    </li>
                    <li>
                      <Text align={TextAlign.justify} isStretched>
                        <b>Certification.</b> Without limiting the foregoing, Client acknowledges
                        and agrees that, if Client takes “adverse action” as defined in the FCRA
                        based in whole or in part on a Report with respect to a Customer, Client
                        shall provide the Customer with an adverse action notice (an “AAN”) in
                        compliance with FCRA. Client agrees to immediately notify Reseller and
                        Snappt each time Client takes adverse action against an Applicant based in
                        whole or in part on a Report, including providing Reseller and Snappt with a
                        copy of the AAN that Client transmitted to the Applicants.
                      </Text>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <Text isStretched>
                <b>ADDITIONAL CLIENT OBLIGATIONS</b>
              </Text>
              <Gap height={1} />

              <ol>
                <li>
                  <Text align={TextAlign.justify} isStretched>
                    <b>Accounts.</b> Client will appoint one or more Authorized Users who will have
                    sole responsibility for the assignment and management of Authorized Users’
                    Accounts (“Administrators”). As between Client and Snappt, Client will be solely
                    responsible for providing the login and password information that will permit
                    Administrators and Authorized Users to access and use the Services (“Account
                    Credentials”). Client will take commercially reasonable efforts to protect
                    Account Credentials from unauthorized use or disclosure. Client will ensure that
                    Administrators and Authorized Users do not share their Account Credentials with
                    any other person and do not permit any other person to access and use the
                    Managed Service through their Accounts. Client will ensure that each
                    Administrator and Authorized User accessing or using the Services complies with
                    this Agreement and the MSA. Client is fully responsible for any authorized or
                    unauthorized use of the Services via the Account Credentials.
                  </Text>
                </li>
                <li>
                  <Text align={TextAlign.justify} isStretched>
                    <b>Compliance with Laws.</b> Client shall, and cause its Administrators and
                    Authorized Users, comply with all laws applicable to Client’s and its
                    Administrators’ and Authorized Users’ access and use of the Service, including,
                    without limitation, utilizing the reports available through the Service in
                    compliance with Laws. Client acknowledges that the Services (including any
                    Reports provided as part of the Services) provided under this Agreement may be
                    considered to be “consumer reports” within the meaning of the FCRA and Client
                    certifies that it will only use the reports for such “permissible purposes” (as
                    defined in the FCRA) and will not otherwise violate the provisions of the FCRA
                    in connection herewith. Client further acknowledges and agrees that (a) use of
                    the Services and compliance with its obligations under this Agreement are not
                    intended for, and do not constitute, Client’s compliance with any applicable
                    laws and (ii) Snappt makes no representation, warranty or covenant that Client’s
                    use of the Service will satisfy or otherwise comply with Client’s obligations as
                    a landlord or financial institution under applicable laws (including, without
                    limitation, pursuant to the FCRA).
                  </Text>
                </li>
                <li>
                  <Text align={TextAlign.justify} isStretched>
                    <b>Notification of Unauthorized Use.</b> Client will immediately notify Reseller
                    and Snappt in writing of any actual or reasonably suspected unauthorized use of
                    any Account, Account Credentials, Client Data or the Services that comes to
                    Client’s attention. In the event of any such unauthorized use, Client will take
                    all steps necessary to terminate such unauthorized use. Client will provide
                    Snappt with such cooperation and assistance related to any such unauthorized use
                    as Snappt may reasonably request.
                  </Text>
                </li>
              </ol>
            </li>
            <li>
              <Text isStretched>
                <b>PROPRIETARY RIGHTS</b>
              </Text>
              <Gap height={1} />
              <ol>
                <li>
                  <Text align={TextAlign.justify} isStretched>
                    <b>Ownership by Client.</b> Snappt’s use, collection and retention of Client
                    Data shall be as set forth in the applicable terms of its online privacy policy
                    (“Privacy Policy”) found at&nbsp;
                    <u>
                      <a
                        href="https://snappt.com/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        www.snappt.com/privacy-policy
                      </a>
                    </u>
                    , which is incorporated herein by reference. Client hereby agrees to be bound by
                    Snappt's Privacy Policy and consents to having Client Data processed in
                    accordance with the Privacy Policy. As between Client and Snappt, Client retains
                    ownership of any and all right and interest in and to the Client’s electronic
                    data, text, or other data, including without limitation Applicant Data, that is
                    transmitted, stored, retrieved or processed by Client, an Applicant or Snappt
                    in, to or through the Services.. Client hereby grants Snappt the right and
                    non-exclusive license to use the Client Data to provide the Services to Client
                    or any Administrator or Authorized User, to improve the Services and in
                    connection with Snappt’s other products and services, new product development,
                    and to assess and internally report the performance of the Service. In addition
                    to the license set forth above, Snappt may use and disclose Client Data as
                    reasonably necessary to comply with laws, cooperate with law enforcement
                    agencies, or attempt to prevent or respond to illegal conduct, fraud, abuse, or
                    a threat to the security or integrity of systems or data including the Services
                    or Client Data. Client represents and warrants to Snappt that: (a) Client or its
                    licensors own all right, title and interest in and to the Client Data; (b)
                    Client has the necessary rights in the Client Data to provide the Client Data to
                    Snappt and grant the rights to Snappt contemplated by this Agreement; and (c)
                    use of the Client Data or the Services by Client will not violate any Law or
                    otherwise violate the rights of any third party. As used herein, “Applicant
                    Data” means information provided by Applicants that is then submitted by Client
                    or the Applicant to the Service.
                  </Text>
                </li>
                <li>
                  <Text align={TextAlign.justify} isStretched>
                    <b>Ownership by Snappt.</b> As between Snappt and Client, Snappt owns and
                    reserves all right, title and interest in and to the Services, other than the
                    rights explicitly granted to Client in this Agreement. Notwithstanding anything
                    herein to the contrary, Snappt may use, reproduce, sell, publicize, or otherwise
                    exploit Aggregate Data in any way, in its sole discretion, subject to compliance
                    with applicable laws. For this purpose, “Aggregate Data” means Applicant Data
                    with the names, addresses and other personally identifiable information of
                    Applicants removed. In the course of this Agreement, Client may provide
                    comments, suggestions and recommendations to Snappt with respect to
                    modifications, enhancements, improvements and other changes to the Services
                    (collectively, “Feedback”). Client hereby grants to Snappt a non-exclusive,
                    worldwide, royalty-free, irrevocable, perpetual, assignable, transferable right
                    and license to use and incorporate any Feedback into the Services, any
                    derivative works thereof, and all future products and services developed by
                    Snappt.
                  </Text>
                </li>
                <li>
                  <Text align={TextAlign.justify} isStretched>
                    <b>Open Source Materials.</b> The Services may contain “open-source” materials
                    subject to the GNU General Public License (“GPL”), Apache License (“Apache”), or
                    other open-source licenses (collectively, “Open-Source Materials”). In such
                    event, both Client’s and Snappt’s rights and obligations with respect to such
                    Services, or modification, distribution, or sublicensing of any such Service
                    and/or any Open-Source Materials, shall be subject to all terms and conditions
                    of the applicable open-source license. Snappt makes no claim of ownership of, or
                    any warranties with respect to, any Open-Source Materials (or modification,
                    derivative work, distribution, or sublicensing thereof), and such Open-Source
                    Materials are supplied solely in accordance with the license agreements
                    accompanying such materials. In the event of any inconsistency between this
                    Agreement and the applicable open-source license, the open-source license shall
                    prevail.
                  </Text>
                </li>
              </ol>
            </li>
            <li>
              <Text>
                <b>LIMITATION OF LIABILITY</b>
              </Text>
              <Gap height={1} />
              <ol>
                <li>
                  <Text align={TextAlign.justify} isStretched>
                    <b>Disclaimer of Consequential and Related Damages.</b> SNAPPT WILL NOT BE
                    LIABLE (WHETHER BASED IN CONTRACT, TORT, WARRANTY OR OTHERWISE, INCLUDING
                    NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED) TO CLIENT OR ANY OTHER PARTY FOR
                    ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES ARISING
                    OUT OF OR IN RELATION TO THIS AGREEMENT OR THE SERVICES (INCLUDING DAMAGES FOR
                    LOSS OF PROFIT OR GOODWILL), EVEN IF SUCH PARTY HAS BEEN ADVISED OF THE
                    POSSIBILITY OF SUCH DAMAGES.
                  </Text>
                </li>
                <li>
                  <Text align={TextAlign.justify} isStretched>
                    <b>Limitation on Liability.</b> THE ENTIRE LIABILITY OF SNAPPT ARISING OUT OF OR
                    IN RELATION TO THIS AGREEMENT FOR ANY LOSS OR DAMAGE, REGARDLESS OF THE FORM OF
                    ACTION, SHALL BE LIMITED TO ACTUAL DIRECT DAMAGES THAT ARE REASONABLY INCURRED;
                    PROVIDED THAT IN NO EVENT SHALL SNAPPT’S ENTIRE LIABILITY EXCEED $100.
                    NOTWITHSTANDING ANYTHING HEREIN TO THE CONTRARY, SNAPPT WILL NOT BE LIABLE FOR
                    ANY DAMAGES INCURRED BY CLIENT TO THE EXTENT ARISING FROM ANY UNAUTHORIZED
                    ACCESS RESULTING FROM THE ACTIONS OF CLIENT OR ANY THIRD PARTY OTHER THAN
                    SNAPPT’S REPRESENTATIVES, UNLESS SUCH DAMAGES WERE INCURRED IN CONNECTION WITH A
                    THIRD PARTY GAINING UNAUTHORIZED ACCESS TO THE SERVICES DUE SOLELY TO SNAPPT’S
                    VIOLATION OF APPLICABLE LAW.
                  </Text>
                </li>
                <li>
                  <Text align={TextAlign.justify} isStretched>
                    <b>Disclaimer.</b> SNAPPT HEREBY SPECIFICALLY DISCLAIMS ANY WARRANTIES, EXPRESS
                    OR IMPLIED, IN CONNECTION WITH THE SERVICES, INCLUDING ANY IMPLIED WARRANTIES OF
                    FITNESS, MERCHANTABILITY OR NON-INFRINGEMENT, AND ANY WARRANTIES ARISING BY
                    COURSE OF PERFORMANCE OR TRADE USAGE. FURTHER, WITHOUT LIMITING THE GENERALITY
                    OF THE FOREGOING, SNAPPT DOES NOT WARRANT THAT THE SERVICES WILL BE FREE OF
                    DEFECTS OR THAT THE OPERATION OF THE SERVICES WILL BE UNINTERRUPTED, SECURE,
                    ERROR-FREE OR FREE FROM LOSS OR DELETION OF CLIENT DATA.
                  </Text>
                </li>
                <li>
                  <Text align={TextAlign.justify} isStretched>
                    <b>Force Majeure.</b> Snappt will not be liable for any failure or delay in
                    performing its obligations under this Agreement, or for any loss or damage
                    resulting therefrom, due to acts of God, pandemics, public emergencies, supply
                    chain disruptions, civil insurrection, terrorist activities, riots, fires, major
                    power outages, Internet outages, telecommunications outages and similar causes
                    beyond Snappt’s control. In the event of such failure or delay, the date of
                    delivery or performance will be extended for a period not to exceed the time
                    lost by reason of the failure or delay.
                  </Text>
                </li>
              </ol>
            </li>
            <li>
              <Text isStretched isBold>
                GENERAL
              </Text>
              <Gap height={1} />

              <ol>
                <li>
                  <Text align={TextAlign.justify} isStretched>
                    <b>Governing Law.</b> This Agreement, and any disputes arising out of or
                    relating to this Agreement, shall be governed by, construed and enforced in all
                    respects in accordance with the laws of the State of Delaware, without regard to
                    the conflict of laws or choice of law provisions thereof. The Parties agree that
                    all actions and proceedings arising out of or relating to this Agreement shall
                    be brought only in a state or federal court located in Los Angeles County,
                    California. The Parties hereby consent to such venue and to the jurisdiction of
                    such courts over the subject matter of such proceeding and themselves.
                  </Text>
                </li>
                <li>
                  <Text align={TextAlign.justify} isStretched>
                    <b>Notices.</b> Client hereby consents to receive notice hereunder care of
                    Reseller in accordance with Snappt’s agreement with Reseller, and that any
                    notice to Reseller with respect to this Agreement shall be deemed notice
                    hereunder. If Client has any disputes with respect to this Agreement or the
                    Services, such disputes shall be directed to Reseller for resolution in
                    accordance with Snappt’s agreement with Reseller. Without limiting the
                    foregoing, any notice to Snappt shall be sent to the attention of the General
                    Counsel at <a href="mailto:legal@snappt.com"> legal@snappt.com</a>.
                    Notwithstanding anything herein to the contrary, any support requests from
                    Client shall be directed exclusively to Reseller.
                  </Text>
                </li>
                <li>
                  <Text align={TextAlign.justify} isStretched>
                    <b>Severability.</b> All provisions of this Agreement are deemed to be separate
                    and distinct covenants. In case any provision of this Agreement will be held
                    invalid, illegal or unenforceable, the remaining provisions of this Agreement
                    will not in any way be affected or impaired. The parties agree that if any
                    provision is determined by any court to be invalid or unenforceable by reason of
                    such provision extending for too great a period of time or over too broad a
                    scope, then such provision will be interpreted to extend over the maximum period
                    of time and the maximum scope that such court determines to be valid and
                    enforceable.
                  </Text>
                </li>
                <li>
                  <Text align={TextAlign.justify} isStretched>
                    <b>Relationship.</b> The parties acknowledge and agree that their relationship
                    is and shall remain solely and exclusively that of independent contractors.
                    Nothing in this Agreement will be construed to create a partnership, joint
                    venture or agency relationship between the parties, and in no event will either
                    party be, claim to be or be deemed to be an employee, agent or partner of the
                    other party by reason of or with respect to this Agreement or any Services.
                  </Text>
                </li>
                <li>
                  <Text align={TextAlign.justify} isStretched>
                    <b>Updates.</b> This Agreement, in the form posted at the time of Client’s use
                    of the applicable Service(s) to which it applies shall govern such use
                    (including transactions entered during such use). Snappt reserves the right to
                    change the terms and conditions of this Agreement or to modify or discontinue
                    the Service(s) offered by Snappt at any time (including modifying the
                    functionality or features or releasing a new version). In the event of any
                    changes to this Agreement, Snappt will provide notice by posting the revised
                    Agreement at this URL. Any such changes will go into effect on the effective
                    date shown in the revised Agreement. By continuing to use any Service(s) after
                    the new effective date, Client agrees to be bound by such changes. If the
                    modified terms are not acceptable to Client, Client’s sole recourse is to cease
                    using the Service(s). Therefore, is hereby advised to review the posted terms of
                    this Agreement prior to each use of the Service(s) (e.g., prior to each
                    transaction or submission). The terms of this Agreement that applied when Client
                    previously used the Service(s) will continue to apply to such prior use (i.e.,
                    changes and additions are prospective only) unless mutually agreed. In the event
                    any notice to Client of new, revised or additional terms is determined by a
                    tribunal to be insufficient, the prior terms of the Agreement shall continue
                    until sufficient notice to establish a new agreement occurs.
                  </Text>
                </li>
              </ol>
            </li>
          </ol>

          <Text align={TextAlign.center} isStretched>
            [END OF TERMS]
          </Text>
        </ListCounterContainer>
      </Card>
    </Section>
  );
};
