/**
 * This hook is used to check if a feature flag is enabled or not.
 */
import { configVars } from 'src/helpers';
import useApi from 'src/hooks/useApi';
const FEATURE_FLAG_API_URL = configVars.feature_flag_api_url;

type FFProjectName = 'fraud-platform' | 'enterprise-partner-api';
export type AuthType = 'unauthenticated' | 'authenticated';

type FeatureFlagResponse = boolean;

export enum FeatureFlagKeys {
  applicantDupExperienceRedesign = 'applicant-dup-experience-redesign',
  connectedPayroll = 'connected-payroll'
}

const featureFlagRoutes = {
  unathenticated: `unauthenticated/feature-flag`,
  authenticated: `feature-flag`
};

interface FFParams {
  projectName: FFProjectName;
  featureFlagKey: FeatureFlagKeys;
  authType?: AuthType;
  filter?: string;
}

export function useFeatureFlag(params: FFParams) {
  const { projectName, featureFlagKey, authType = 'unauthenticated', filter } = params;
  const url = `${FEATURE_FLAG_API_URL}/${
    authType === 'unauthenticated'
      ? featureFlagRoutes.unathenticated
      : featureFlagRoutes.authenticated
  }/${projectName}?featureFlagKey=${featureFlagKey}${filter ? `&filter=${filter}` : ''}`;

  const { makeRequest, data, loading } = useApi<FeatureFlagResponse>({
    url: url,
    loadsOnMount: false
  });

  const onGetFeatureFlagValue = () => {
    makeRequest();
  };

  return { onGetFeatureFlagValue, data, loading };
}
