import { Row } from 'src/components/atoms/Row';
import styled from 'styled-components/macro';

export const ModalFooter = styled(Row)`
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 2rem;

  button {
    flex: 1;
  }
`;
