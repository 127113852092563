export interface CreateApplicationRequest {
  companyShortId?: string;
  propertyShortId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  applicantIdentifier?: string;
}

export interface CreateApplicationResponse {
  token?: string;
  id?: string;
  applicantDetailId?: string;
}

export interface ApplicationSubmitResponse {
  applicantId?: string;
  error?: string;
}

export interface SessionApplication {
  id: string;
  firstName?: string | null;
  middleInitial?: string | null;
  lastName?: string | null;
  email?: string | null;
  notificationEmail?: string | null;
  phone?: string | null;
  unit?: string | null;
  hasPreviouslySubmitted?: boolean | null;
  metadata?: { [key: string]: string };
  applicantDetailId?: string | null;
  propertyShortId?: string | null;
}

export type SessionApplicationUpsertBody = Partial<SessionApplication>;

export enum PropertyStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED'
}
export interface SessionProperty {
  id: string;
  shortId: string;
  name: string;
  entityName?: string | null;
  logo?: string | null;
  phone?: string | null;
  phoneIsRequired?: boolean | null;
  website?: string | null;
  unit?: string | null;
  unitIsRequired?: boolean | null;
  address: string;
  city: string;
  state?: string | null;
  zip: string;
  status?: PropertyStatus | null;
  supportedDoctypes?: Record<string, number>;
  bankStatement?: number | null;
  paystub?: number | null;
  email: string;
  companyId?: string | null;
  companyShortId: string | null;
  applicantLink: string;
  leasingTeamLink: string;
  insertedAt: Date;
  updatedAt: Date;
}

export enum DupApplicationType {
  UNAUTHENTICATED_USER = 'unauthenticated_user',
  LEASING_TEAM = 'leasing_team',
  AOA = 'AOA'
}

export enum UnauthenticateSessionProofType {
  Paystub = 'PAYSTUB',
  BankStatement = 'BANK_STATEMENT',
  CashAppStatement = 'CASH_APP_STATEMENT',
  CreditDebitCardStatement = 'CREDIT_DEBIT_CARD_STATEMENT',
  DepartmentOfVeteransAffairsBenefitLetter = 'DEPARTMENT_OF_VETERANS_AFFAIRS_BENEFIT_LETTER',
  InvestmentAccount = 'INVESTMENT_ACCOUNT',
  SocialSecurityBenefitsLetter = 'SOCIAL_SECURITY_BENEFITS_LETTER',
  SocialSecurityStatement = 'SOCIAL_SECURITY_STATEMENT',
  TaxTranscript = 'TAX_TRANSCRIPT',
  UtilityBill = 'UTILITY_BILL'
}

export enum ProofResult {
  Pending = 'PENDING',
  Clean = 'CLEAN',
  Edited = 'EDITED',
  Undetermined = 'UNDETERMINED',
  CleanProceedWithCaution = 'CLEAN_PROCEED_WITH_CAUTION'
}

export interface ProofResponse {
  id: string;
  fileName?: string;
  unauthenticatedSessionId: string;
  insertedAt: string;
  type: UnauthenticateSessionProofType;
  processStatus: string;
  result: ProofResult;
}

export interface CreateProofRequest {
  type: UnauthenticateSessionProofType;
  upload: File;
}

export type JobErrorType =
  | 'UnsupportedFileType'
  | 'PDFParseError'
  | 'PrintToPDFError'
  | 'PdfContainsTextError'
  | 'PageLimitError'
  | 'EncryptedPdfError'
  | 'PDFOver25MBError'
  | 'GenericError';

export enum ProofType {
  Paystub = 'PAYSTUB',
  BankStatement = 'BANK_STATEMENT'
}

export enum ProofSuggestedRuling {
  NotRun = 'NOT_RUN',
  Clean = 'CLEAN',
  Edited = 'EDITED',
  Flagged = 'FLAGGED'
}

export enum ProofResultCleanProceedWithCautionReason {
  NONE = 'NONE',
  NEW_DOCUMENT = 'NEW_DOCUMENT',
  POSSIBLE_MALICIOUS = 'POSSIBLE_MALICIOUS'
}

export enum ProofResultEditedReason {
  NONE = 'NONE',
  TEXT_INSERTION = 'TEXT_INSERTION',
  FRAUDULENT_PDF_PRODUCER = 'FRAUDULENT_PDF_PRODUCER',
  FONT_FAIL = 'FONT_FAIL',
  CREATION_DATE = 'CREATION_DATE'
}

export enum ProofResultInsufficientReason {
  NONE = 'NONE',
  SCANNED_DOCUMENTS = 'SCANNED_DOCUMENTS',
  PRINT_TO_PDF = 'PRINT_TO_PDF',
  INVALID_DOCUMENT_TYPE = 'INVALID_DOCUMENT_TYPE',
  MERGED_DOCUMENT = 'MERGED_DOCUMENT',
  FOREIGN_ISSUED_DOCUMENT = 'FOREIGN_ISSUED_DOCUMENT',
  CROPPED_DOCUMENT = 'CROPPED_DOCUMENT',
  CUSTOM = 'CUSTOM'
}

export interface Proof {
  id: string;
  fileName?: string;
  entry_id?: string;
  result: ProofResult;
  suggested_ruling: ProofSuggestedRuling;
  note?: string;
  type: UnauthenticateSessionProofType;
  thumb: string;
  jobs_error?: JobErrorType[];
  result_edited_reason: ProofResultEditedReason[];
  result_insufficient_reason: ProofResultInsufficientReason;
  result_clean_proceed_with_caution_reason: ProofResultCleanProceedWithCautionReason;
}
