import { useEffect } from 'react';
import { ButtonColor, ButtonVariant } from 'src/components/atoms/Button';
import { Icon } from 'src/components/atoms/Icon';
import { Line } from 'src/components/atoms/Line';
import { Loader } from 'src/components/atoms/Loader';
import { Row } from 'src/components/atoms/Row';
import { TextVariant } from 'src/components/atoms/Text';
import {
  AccountLogoImage,
  AccountName,
  ConnectedAccount,
  ConnectedAccounts,
  DisconnectLink
} from 'src/components/DUP/molecules/WizardConnectedPayrollSection/styles';
import {
  isAdditionalDocumentProofType,
  isIncomeDocumentProofType
} from 'src/components/DUP/molecules/WizardSubmit';
import useLanguage from 'src/context/Language/useLanguage';
import getDocumentTypes from 'src/features/DUP/helpers/getDocumentTypes';
import getProofErrorMessage, {
  PROOF_ERROR_KEYS
} from 'src/features/DUP/helpers/getProofErrorMessage';
import { useArgyleLink } from 'src/hooks/useArgyleLink';
import { FeatureFlagKeys, useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { DupScreenStepProps } from 'src/pages/DUPPage/DUPWizard';
import { Proof, SessionApplication } from 'src/types/api';
import {
  DocumentRow,
  EditButton,
  NoDocumentsContainer,
  PersonalInfoContainer,
  ReviewContainer,
  ReviewSectionContent,
  SectionContainer,
  SectionHeader,
  SectionTitle,
  StyledText
} from './styles';

const ApplicationInfo = ({
  application,
  setActiveStep,
  setHasPayroll,
  proofs
}: Pick<DupScreenStepProps, 'application' | 'setActiveStep'> & {
  proofs: Proof[];
  setHasPayroll: (hasPayroll: boolean) => void;
}) => {
  const { translate: t } = useLanguage();

  const incomeDocuments = proofs.filter(isIncomeDocumentProofType);
  const additionalDocuments = proofs.filter(isAdditionalDocumentProofType);

  const {
    loading: featureFlagValueLoading,
    onGetFeatureFlagValue,
    data: isConnectedPayrollFFEnabled
  } = useFeatureFlag({
    projectName: 'fraud-platform',
    featureFlagKey: FeatureFlagKeys.connectedPayroll
  });

  useEffect(() => {
    onGetFeatureFlagValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (featureFlagValueLoading) {
    return <Loader isFixed />;
  }

  return (
    <ReviewContainer>
      <ReviewInfoSection
        title={t('dup_wizard_step_1_title')}
        step={1}
        setActiveStep={setActiveStep}
      >
        <PersonalInfo application={application} />
      </ReviewInfoSection>
      <ReviewInfoSection
        title={t('dup_wizard_income_documents')}
        step={2}
        setActiveStep={setActiveStep}
      >
        <DocumentList proofs={incomeDocuments} />
      </ReviewInfoSection>
      <ReviewInfoSection
        title={t('dup_wizard_step_3_title')}
        step={3}
        setActiveStep={setActiveStep}
      >
        <DocumentList proofs={additionalDocuments} />
      </ReviewInfoSection>

      {isConnectedPayrollFFEnabled && (
        <ReviewInfoSection
          title={t('dup_wizard_proof_of_income')}
          step={2}
          setActiveStep={setActiveStep}
        >
          <ConnectedPayrollAccounts setHasPayroll={setHasPayroll} />
        </ReviewInfoSection>
      )}
    </ReviewContainer>
  );
};

const ReviewInfoSection = ({
  title,
  setActiveStep,
  step,
  children
}: {
  title: string;
  setActiveStep: (step: number) => void;
  step: number;
  children: React.ReactNode;
}) => {
  return (
    <>
      <Line />
      <SectionContainer>
        <SectionHeader>
          <SectionTitle isBold>{title}</SectionTitle>
          <EditButton
            name="edit"
            variant={ButtonVariant.outline}
            color={ButtonColor.tertiary}
            onClick={() => setActiveStep(step - 1)}
          >
            Edit
          </EditButton>
        </SectionHeader>
        <ReviewSectionContent>{children}</ReviewSectionContent>
      </SectionContainer>
    </>
  );
};

const PersonalInfo = ({ application }: { application: SessionApplication }) => {
  const { unit, firstName, middleInitial, lastName, phone, email } = application;
  const { translate: t } = useLanguage();

  return (
    <PersonalInfoContainer>
      <StyledText>
        {firstName} {middleInitial} {lastName}
      </StyledText>
      {!!unit && (
        <StyledText>
          {t('dup_wizard_unit')} {unit}
        </StyledText>
      )}
      {!!phone && <StyledText>{phone}</StyledText>}
      <StyledText>{email}</StyledText>
    </PersonalInfoContainer>
  );
};

const DocumentList = ({ proofs }: { proofs: Proof[] }) => {
  const { translate: t } = useLanguage();

  return proofs.length ? (
    <>
      {proofs.map((proof, i) => {
        const label = getDocumentTypes(t).find((type) => type.value === proof.type)?.label;
        const error = proof.jobs_error?.length ? proof.jobs_error[0] : '';

        return (
          <DocumentRow key={proof.id} error={Boolean(error)}>
            <Icon icon="file_grey" />
            <Row wrap="wrap">
              <span>{proof.fileName || `${label} ${proofs.length > 1 && `#${i + 1}`}`}</span>
              {Boolean(error) && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: getProofErrorMessage(error as keyof typeof PROOF_ERROR_KEYS, t)
                  }}
                />
              )}
            </Row>
          </DocumentRow>
        );
      })}
    </>
  ) : (
    <NoDocumentsContainer>{t('dup_wizard_no_documents_uploaded')}</NoDocumentsContainer>
  );
};

const ConnectedPayrollAccounts = ({
  setHasPayroll
}: {
  setHasPayroll: (hasPayroll: boolean) => void;
}) => {
  const { translate: t } = useLanguage();
  const { accountsConnected, isLoadingAccounts, disconnectAccount, isDeletingAccounts } =
    useArgyleLink();

  useEffect(() => {
    if (!isLoadingAccounts && !isDeletingAccounts) {
      setHasPayroll(!!accountsConnected?.length);
    }
  }, [accountsConnected, isLoadingAccounts, isDeletingAccounts, setHasPayroll]);

  if (isLoadingAccounts || isDeletingAccounts) {
    return <Loader isFixed />;
  }

  return accountsConnected?.length ? (
    <ConnectedAccounts>
      {accountsConnected.map((account) => (
        <ConnectedAccount key={account.accountId}>
          <AccountLogoImage alt={account.name} src={account.logoUrl} />
          <AccountName variant={TextVariant.small}>{account.name}</AccountName>
          <DisconnectLink onClick={() => disconnectAccount(account.accountId)}>
            {t('dup_wizard_disconnect_account')}
          </DisconnectLink>
        </ConnectedAccount>
      ))}
    </ConnectedAccounts>
  ) : (
    <NoDocumentsContainer>{t('dup_wizard_no_connected_accounts')}</NoDocumentsContainer>
  );
};

export default ApplicationInfo;
