import theme from 'src/theme';
import { DupApplicationType } from 'src/types/api';

export const Color = {
  [DupApplicationType.UNAUTHENTICATED_USER]: {
    dark: theme.color.blue[700],
    light: theme.color.blue[70],
    icon: theme.color.blue[700],
    content: theme.color.gray[50]
  },
  [DupApplicationType.LEASING_TEAM]: {
    dark: theme.color.purple[700],
    light: theme.color.purple[100],
    icon: theme.color.purple[700],
    content: theme.color.purple[100]
  },
  [DupApplicationType.AOA]: {
    dark: theme.color.blue[700],
    light: theme.color.blue[70],
    icon: theme.color.blue[700],
    content: theme.color.gray[50]
  }
};
