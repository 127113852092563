import { Icon as Logo } from 'src/components/atoms/Icon';
import { FooterRow } from 'src/components/DUP/molecules/WizardFooter/styles';

const WizardFooter = ({ hideOn }: { hideOn: 'mobile' | 'desktop' }) => {
  return (
    <FooterRow hideOn={hideOn}>
      <Logo icon="powered_by_snappt" />
    </FooterRow>
  );
};

export default WizardFooter;
