import PropTypes from 'prop-types';
import { StyledReactSVG } from './styles';

const PUBLIC_URL = process.env.PUBLIC_URL;

export enum IconColor {
  muted = 'muted',
  primary = 'primary',
  black = 'black',
  secondary = 'secondary',
  white = 'white',
  gray = 'gray',
  gray600 = 'gray600',
  gray700 = 'gray700',
  gray900 = 'gray900',
  blue700 = 'blue700',
  red = 'red'
}

export type IconProps = {
  icon: string;
  color?: keyof typeof IconColor;
  className?: string;
};

const Icon: React.FC<IconProps> = (props) => {
  const { icon, color, className } = props;

  return (
    <StyledReactSVG
      className={className}
      color={color}
      src={`${PUBLIC_URL}/assets/images/${icon}.svg`}
    />
  );
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf<keyof typeof IconColor>(Object.values(IconColor))
};

Icon.defaultProps = {
  icon: 'add',
  className: ''
};

export default Icon;
