import { colors, fonts } from './constants';
import './style.scss';

const buttons = {
  fontFamily: fonts.normal,
  fontWeight: 500,
  cursor: 'pointer',
  border: '1px solid',
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '0.125rem',
  disabled: {
    cursor: 'default',
    color: colors.white,
    background: colors.gray[300],
    borderColor: colors.gray[300]
  },
  sizes: {
    big: {
      fontSize: '1.5rem',
      padding: '1.1rem 3rem'
    },
    normal: {
      fontSize: '0.875rem',
      padding: '0.375rem 0.75rem',
      height: '2.75rem'
    },
    medium: {
      fontSize: '0.95rem',
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
      height: '40px'
    }
  },
  colors: {
    primary: {
      variants: {
        contained: {
          background: 'transparent',
          hover: {
            transition: 'all 0.15s linear'
          }
        },
        outline: {
          background: 'transparent',
          hover: {
            transition: 'all 0.15s linear'
          }
        },
        ghost: {
          background: 'transparent',
          borderColor: 'transparent',
          hover: {
            transition: 'all 0.15s linear'
          }
        }
      }
    },
    secondary: {
      variants: {
        contained: {
          background: 'transparent',
          hover: {
            transition: 'all 0.15s linear'
          }
        },
        outline: {
          background: 'transparent',
          hover: {
            transition: 'all 0.15s linear'
          }
        },
        ghost: {
          background: 'transparent',
          borderColor: 'transparent',
          hover: {
            transition: 'all 0.15s linear'
          }
        }
      }
    },
    tertiary: {
      variants: {
        contained: {
          background: 'transparent',
          hover: {
            transition: 'all 0.15s linear'
          }
        },
        outline: {
          background: 'transparent',
          hover: {
            transition: 'all 0.15s linear'
          }
        },
        ghost: {
          background: 'transparent',
          borderColor: 'transparent',
          hover: {
            transition: 'all 0.15s linear'
          }
        }
      }
    },
    cancel: {
      variants: {
        contained: {
          background: 'transparent',
          hover: {
            transition: 'all 0.15s linear'
          }
        },
        outline: {
          background: 'transparent',
          hover: {
            transition: 'all 0.15s linear'
          }
        },
        ghost: {
          background: 'transparent',
          borderColor: 'transparent',
          hover: {
            transition: 'all 0.15s linear'
          }
        }
      }
    },
    disabled: {
      variants: {
        contained: {
          background: 'transparent',
          hover: {
            transition: 'all 0.15s linear'
          }
        },
        outline: {
          background: 'transparent',
          hover: {
            transition: 'all 0.15s linear'
          }
        },
        ghost: {
          background: 'transparent',
          borderColor: 'transparent',
          hover: {
            transition: 'all 0.15s linear'
          }
        }
      }
    }
  }
};

export default buttons;
