import { useState } from 'react';
import { ButtonColor, ButtonVariant } from 'src/components/atoms/Button';
import { Checkbox } from 'src/components/atoms/Checkbox';
import Link, { LinkTarget } from 'src/components/atoms/Link';
import ToolTip, { ToolTipDirection } from 'src/components/atoms/ToolTip/ToolTip';
import ApplicationInfo from 'src/components/DUP/molecules/WizardSubmit/ApplicationInfo';
import useLanguage from 'src/context/Language/useLanguage';
import { makeRequest } from 'src/hooks/useResource';
import { useSnackbarProvider } from 'src/hooks/useSnackbarProvider';
import { DupScreenStepProps } from 'src/pages/DUPPage/DUPWizard';
import { StyledButton as Button } from 'src/pages/DUPPage/styles';
import { ApplicationSubmitResponse, Proof } from 'src/types/api';
import { ActionContainer, Container, Terms, TermsRow } from './styles';

const getDisabledTextKey = (
  isSubmittable: boolean,
  isProofsHaveErrors: boolean,
  proofIsProcessing: boolean
) => {
  if (!isSubmittable) {
    return 'dup_footer_pre_submit_incomplete_form';
  }

  if (isProofsHaveErrors) {
    return 'dup_footer_pre_submit_proofs_has_error';
  }

  if (proofIsProcessing) {
    return 'dup_footer_pre_submit_proofs_processing';
  }
  return '';
};

const WizardSubmit = (props: DupScreenStepProps & { proofs: Proof[] }) => {
  const { application, onFinished, information, setActiveStep, proofs } = props;
  const { translate: t } = useLanguage();
  const { showSnackbar, SnackTypes, VariantType } = useSnackbarProvider();

  const [isTOSChecked, setIsTOSChecked] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [hasDocuments, _setHasDocuments] = useState<boolean>(!!proofs.length);

  const setHasDocuments = (hasDocuments: boolean) => {
    _setHasDocuments(!!proofs.length || hasDocuments);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await onSubmit();
    setIsSubmitting(false);
  };

  const onSubmit = async () => {
    const response = await makeRequest<ApplicationSubmitResponse>(`/session/submit`, 'POST');
    if ('error' in response) {
      showSnackbar(VariantType.error, t('dup_submission_error_title'), SnackTypes.none);
    } else if ('applicantId' in response) {
      onFinished();
    }
  };

  const isProofsHaveErrors = proofs.some((proof) => Boolean(proof.jobs_error?.length));
  const disableTextKey = getDisabledTextKey(
    isTOSChecked && hasDocuments,
    isProofsHaveErrors,
    false
  );

  return (
    <Container>
      <ApplicationInfo
        application={application}
        setActiveStep={setActiveStep}
        proofs={proofs}
        setHasPayroll={setHasDocuments}
      />
      <TermsRow>
        <Checkbox
          key={`terms_${isTOSChecked}_`}
          onClick={(obj) => setIsTOSChecked(obj.isChecked)}
          name="tosAndPP"
          showLabel={false}
          value="yes"
          label={t('dup_footer_i_agree_to_the_terms_and_pp')}
          isChecked={isTOSChecked}
        />
        <Terms className="label">
          {t('dup_footer_i_agree_to_the')}
          <Link to={information.url_terms_of_service} isExternal target={LinkTarget.blank}>
            {t('dup_wizard_terms_of_service')}
          </Link>
          {t('dup_footer_and')}
          <Link to={information.url_privacy_policy} isExternal target={LinkTarget.blank}>
            {t('dup_footer_privacy_policy')}
          </Link>
        </Terms>
      </TermsRow>
      <ActionContainer justify="space-between">
        <Button
          name="back"
          onClick={() => {
            setActiveStep(2);
          }}
          variant={ButtonVariant.outline}
        >
          {t('dup_button_label_back')}
        </Button>
        <ToolTip
          direction={ToolTipDirection.top_left}
          content={disableTextKey ? t(disableTextKey) : ''}
        >
          <Button
            name="submit"
            onClick={handleSubmit}
            isDisabled={!isTOSChecked || !hasDocuments || isSubmitting || isProofsHaveErrors}
            color={!isTOSChecked ? ButtonColor.disabled : ButtonColor.primary}
            variant={ButtonVariant.contained}
          >
            {t('dup_footer_button_submit')}
          </Button>
        </ToolTip>
      </ActionContainer>
    </Container>
  );
};

export default WizardSubmit;
