import styled from 'styled-components';
import { ToolTipProps } from './ToolTip';

export const ToolTipWrapper = styled.div`
  position: relative;
  -webkit-transform: translateZ(0);
`;

export const ToolTip = styled.span<ToolTipProps>`
  ${({ theme }) => theme.tooltip.main};
  background: ${({ theme }) => theme.tooltip.color};
  box-sizing: border-box;
  position: absolute;
  opacity: 0;
  text-align: left;
  display: inline-table;
  transition: opacity 0.3s;
  width: max-content;
  max-width: 34rem;
  pointer-events: none;
  transition: all 0.25s ease-out;
  top: 0;

  ${ToolTipWrapper}:hover & {
    visibility: visible;
    opacity: 1;
  }

  &.top {
    transform: translateY(-110%) translateX(-55%);
    left: 50%;
    top: 0;
    &::after {
      content: '';
      position: absolute;
      top: 96%;
      left: 50%;
      border-width: 5px;
      border-style: solid;
      border-color: ${({ theme }) => theme.tooltip.color} transparent transparent transparent;
    }
  }

  &.top_left {
    transform: translateY(-110%) translateX(-55%);
    left: -40%;
    top: 0;
    max-width: 380px;
    text-align: center;

    &::after {
      content: '';
      position: absolute;
      top: 96%;
      left: 85%;
      border-width: 5px;
      border-style: solid;
      border-color: ${({ theme }) => theme.tooltip.color} transparent transparent transparent;
    }

    @media ${({ theme }) => theme.device.mobile} {
      max-width: 320px;
      left: -27%;
    }
  }

  &.bottom {
    bottom: -100%;
    transform: translateY(50%) translateX(-54%);
    &::before {
      left: 50.8%;
      top: -0.75rem;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: 0.375rem;
      margin-left: -0.375rem;
      border-bottom-color: ${({ theme }) => theme.tooltip.color};
    }
  }

  &.bottom_fit {
    top: 115%;
    right: 0rem;
    &::before {
      right: 1.5rem;
      top: -0.75rem;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: 0.375rem;
      margin-left: -0.375rem;
      border-bottom-color: ${({ theme }) => theme.tooltip.color};
    }
  }

  &.left {
    right: 2rem;
    &::before {
      right: -0.7rem;
      top: 0.2rem;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: 0.375rem;
      margin-left: -0.375rem;
      border-left-color: ${({ theme }) => theme.tooltip.color};
    }
  }

  &.right {
    left: 1.8rem;
    &::before {
      left: -0.32rem;
      top: 0.2rem;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: 0.375rem;
      margin-left: -0.375rem;
      border-right-color: ${({ theme }) => theme.tooltip.color};
    }
  }

  &.right_center {
    left: 20%;
    transform: translateY(-125%);
    &::before {
      left: -0.32rem;
      top: 0.2rem;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-width: 0.375rem;
      margin-left: -0.375rem;
      border-right-color: ${({ theme }) => theme.tooltip.color};
    }
  }
`;
