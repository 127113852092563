import { colors, devices, redesignColors } from './constants';
import { mainButtons } from './custom/buttons';
import { mainNavigation } from './custom/navigation';
import { mainTooltip } from './custom/tooltip';
import { mainFonts, redesignFonts } from './custom/typography';
import './style.scss';
const theme = {
  button: mainButtons,
  font: mainFonts,
  redesignFont: redesignFonts,
  color: colors,
  redesignColor: redesignColors,
  device: devices,
  navigation: mainNavigation,
  tooltip: mainTooltip
};
export default theme;
