import { ITextKeys as ITextLangKeys } from 'src/context/Language/types';
import { DOCUMENT_TYPES } from '../constants';
import getDefaultTextLang from './getDefaultTextLang';

export const getDocumentTypes = (translate?: (key: ITextLangKeys) => string) => {
  const t = !translate ? getDefaultTextLang : translate;
  return [
    { value: DOCUMENT_TYPES[0].value, label: t('dup_document_type_paystub') }, // PAYSTUB
    { value: DOCUMENT_TYPES[1].value, label: t('dup_document_type_bank_statement') } // BANK_STATEMENT
  ];
};

export default getDocumentTypes;
