import { ButtonVariant } from 'src/components/atoms/Button';
import useLanguage from 'src/context/Language/useLanguage';

import {
  ButtonsContainer,
  Container,
  HelpText
} from 'src/components/DUP/molecules/WizardProofOfIncome/styles';

import { WizardProofOfIncomeProps } from 'src/components/DUP/molecules/WizardProofOfIncome/WizardProofOfIncome';
import { useWizardUpload } from 'src/components/DUP/molecules/WizardUpload/useWizardUpload';
import { StyledButton } from 'src/pages/DUPPage/styles';

export const WizardDocumentUploadSection = (props: WizardProofOfIncomeProps) => {
  const { name, property, onFinished, setActiveStep, type, proofs, refresh } = props;
  const { translate: t } = useLanguage();
  const { WizardUploadEl, proofIsProcessing } = useWizardUpload({
    type,
    proofs,
    refresh,
    property
  });

  return (
    <Container>
      {WizardUploadEl}
      <HelpText>
        {t('dup_wizard_footer_text')}
        <a href="https://vimeo.com/645372425" target="_blank" rel="noreferrer">
          {t('dup_wizard_help_center')}
        </a>
      </HelpText>
      <ButtonsContainer justify="space-between">
        <StyledButton
          name="back"
          onClick={() => {
            setActiveStep(name === 'step3' ? 1 : 0);
          }}
          variant={ButtonVariant.outline}
        >
          {t('dup_button_label_back')}
        </StyledButton>
        <StyledButton
          name="next"
          isDisabled={name === 'step3' && proofIsProcessing}
          onClick={onFinished}
          variant={ButtonVariant.contained}
        >
          {t('dup_button_label_next')}
        </StyledButton>
      </ButtonsContainer>
    </Container>
  );
};
