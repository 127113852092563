import React from 'react';
import UploadFiles from 'src/components/DUP/molecules/UploadFiles/UploadFiles';
import { DupApplicationType, Proof } from 'src/types/api';
import { Container, Content } from './styles';

export type UploadFilesProps = {
  proofs: Proof[];
  type: DupApplicationType;
  refresh: () => Promise<Proof[]>;
  setProofIsProcessing: (isProcessing: boolean) => void;
};
const UploadFileSection: React.FC<UploadFilesProps> = ({
  type = DupApplicationType.UNAUTHENTICATED_USER,
  proofs,
  refresh,
  setProofIsProcessing
}) => {
  return (
    <Container type={type}>
      <Content>
        <UploadFiles
          type={type}
          refresh={refresh}
          proofs={proofs}
          setProofIsProcessing={setProofIsProcessing}
        />
      </Content>
    </Container>
  );
};

export default UploadFileSection;
