import { devices } from 'src/theme/constants';
import styled, { css } from 'styled-components';
import { ColProps } from './Col';
import { RowProps } from './Row';

export const Container = styled.div<RowProps>`
  display: flex;
  ${({ gap }) => gap && `gap: ${gap}rem;`};
  flex-direction: ${({ direction }) => direction};
  flex-wrap: ${({ wrap }) => wrap};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ alignItems }) => alignItems};
  align-content: ${({ alignContent }) => alignContent};

  ${({ columns }) => {
    if (columns) {
      const w = 100 / columns - columns;
      return css`
        > a,
        > div {
          flex: 0 ${w}%;
          display: grid;
        }
      `;
    }
  }}

  @media ${({ theme }) => theme.device.mobile} {
    ${({ wrap }) => wrap !== 'nowrap' && 'flex-direction: column !important;'}
  }
`;

const media = (collapse: keyof typeof devices, styles: string) => {
  return css`
    @media only screen and ${devices[collapse]} {
      ${styles}
      flex-direction: column!important;
    }
  `;
};

export const Column = styled.div<ColProps>`
  flex: ${({ size }) => size};
  ${({ collapse }) => collapse && media(collapse, `display: none;`)};
  align-self: ${({ alignSelf }) => alignSelf};
  text-align: ${({ alignContent }) => alignContent};
`;
