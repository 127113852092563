import * as S from './styles';

export type BodyProps = {
  children: React.ReactNode;
};

const ListCounterContainer: React.FC<BodyProps> = (props: BodyProps) => {
  return <S.Container>{props.children}</S.Container>;
};

export default ListCounterContainer;
