/**
 * This header component is intended to be usd with the DUPWizard component.
 */
import { IconColor } from 'src/components/atoms/Icon';
import { TextColor } from 'src/components/atoms/Text';
import { HeaderProps } from 'src/components/DUP/atoms/Header/Header';
import HideOnMobile from 'src/components/DUP/atoms/HideInMobile';
import { LanguageSelector } from 'src/components/organisms/LanguageSelector';
import {
  Container,
  H1,
  HeaderRow,
  ProgressContainer,
  PropertyName,
  Step,
  Subtitle
} from './styles';

interface StepInformation {
  title: string;
  description: string;
  stepNumber: number;
}

interface ProgressProps {
  activeStep: number;
  // Include information about each step in sequential order
  steps: StepInformation[];
  goToStep: (step: number) => void;
}

type WizardHeaderProps = Omit<HeaderProps, 'information'> &
  ProgressProps & {
    propertyName?: string;
  };

const WizardHeader = ({
  type,
  onSetLanguage,
  propertyName,
  ...progressProps
}: WizardHeaderProps) => {
  const { activeStep, steps } = progressProps;
  const { title, description } = steps.find((s) => s.stepNumber === activeStep) || steps[0];

  return (
    <Container>
      <HeaderRow>
        <PropertyName>{propertyName}</PropertyName>
        <LanguageSelector
          type={type}
          onSetLanguage={onSetLanguage}
          iconColor={IconColor.gray900}
          textColor={TextColor.secondary}
          size="small"
        />
      </HeaderRow>
      {activeStep <= steps.length && (
        <>
          <WizardProgress {...progressProps} />
          <H1>{title}</H1>
          <Subtitle>{description}</Subtitle>
        </>
      )}
    </Container>
  );
};

export const WizardProgress = ({ activeStep, steps, goToStep }: ProgressProps) => {
  return (
    <ProgressContainer>
      {steps.map(({ title, stepNumber }, i) => {
        const isActive = activeStep === stepNumber;
        return (
          <Step
            key={i}
            isActive={isActive}
            onClick={activeStep > 1 ? () => goToStep(stepNumber) : undefined}
          >
            <HideOnMobile>{title}</HideOnMobile>
          </Step>
        );
      })}
    </ProgressContainer>
  );
};

export default WizardHeader;
