import useLanguage from 'src/context/Language/useLanguage';
import { DupScreenStepProps } from 'src/pages/DUPPage/DUPWizard';
import { Container, Row, StyledSubText, StyledText } from './styles';
const PUBLIC_URL = process.env.PUBLIC_URL;

const Success = (props: DupScreenStepProps) => {
  const { property } = props;
  const { translate: t } = useLanguage();

  return (
    <Container>
      <Row>
        <img src={`${PUBLIC_URL}/assets/images/success_squid.png`} alt="Squid Success" />
      </Row>

      <StyledText>{t('dup_wizard_success_congrats')}</StyledText>
      <StyledSubText>
        {t('dup_wizard_success_message', property?.name || 'The property')}
      </StyledSubText>
    </Container>
  );
};

export default Success;
