import useLanguage from 'src/context/Language/useLanguage';

import { WizardProofOfIncomeProps } from 'src/components/DUP/molecules/WizardProofOfIncome/WizardProofOfIncome';
import { UnauthenticateSessionProofType } from 'src/types/api';
import { Instructions, StyledUl } from './styles';

const getDocumentsAmount = (type: string, property: WizardProofOfIncomeProps['property']) => {
  const key = type.toLowerCase();
  if (property?.supportedDoctypes && key in property.supportedDoctypes) {
    return property?.supportedDoctypes[key] || 0;
  }

  return 0;
};

const getTranslationText = (type: string, documentsAmount?: number | null) => {
  if (type === UnauthenticateSessionProofType.Paystub) {
    return 'dup_wizard_instruction_paystubs';
  }
  if (type === UnauthenticateSessionProofType.BankStatement && Boolean(documentsAmount)) {
    return 'dup_wizard_instruction_bank_statements';
  }
  return 'dup_wizard_instruction_additional_docs';
};

export const useWizardUploadInstructions = ({
  property,
  type
}: Pick<WizardProofOfIncomeProps, 'property' | 'type'>) => {
  const { translate: t } = useLanguage();
  const documentsAmount = getDocumentsAmount(type, property);
  const documentTypeText = getTranslationText(type, documentsAmount);
  const documents = documentsAmount?.toString() || '';
  const firstInstruction = documentsAmount
    ? t('dup_wizard_first_instruction', documents, t(documentTypeText))
    : t('dup_wizard_first_instruction_no_amount', t(documentTypeText));

  const instructions = [
    firstInstruction,
    t('dup_wizard_second_instruction'),
    t('dup_wizard_third_instruction')
  ];

  const InstructionsEl = (
    <Instructions>
      <span>Instructions:</span>
      <StyledUl>
        {instructions.map((instruction, index) => (
          <li key={`wiz-upload-instruction-${index}`}>{instruction}</li>
        ))}
      </StyledUl>
    </Instructions>
  );

  return { instructions, InstructionsEl };
};
