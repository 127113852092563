import { Card } from 'src/components/atoms/Card';
import Gap from 'src/components/atoms/Gap';
import { Icon } from 'src/components/atoms/Icon';
import Link, { LinkTarget } from 'src/components/atoms/Link';
import List, { ListItemProps, ListTypes } from 'src/components/atoms/List/List';
import { Row } from 'src/components/atoms/Row';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import { ITextKeys as ITextLangKeys } from 'src/context/Language/types';
import useLanguage from 'src/context/Language/useLanguage';
import { Information } from 'src/features/DUP/helpers/getInformation';
import { DupApplicationType, SessionProperty } from 'src/types/api';
import { Container, TextContainer } from './styles';

export type UploadInstructionsProps = {
  type: DupApplicationType;
  information: Pick<
    Information,
    'instruction_upload_item_one' | 'instruction_upload_item_two' | 'instruction_upload_item_three'
  >;
  property?: Pick<SessionProperty, 'id' | 'bankStatement' | 'paystub'>;
};

const UploadInstructions: React.FC<UploadInstructionsProps> = ({
  type,
  information,
  property
}: UploadInstructionsProps) => {
  const { translate: t } = useLanguage();

  if (!property?.id) {
    return null;
  }

  const listItems: ListItemProps[] = [
    {
      text: information.instruction_upload_item_one
    },
    {
      text: information.instruction_upload_item_two
    },
    ...(information.instruction_upload_item_three
      ? [
          {
            text: information.instruction_upload_item_three
          }
        ]
      : [])
  ];

  return (
    <Container>
      <Row justify="space-between" alignItems="center">
        <Text variant={TextVariant.h3} color={TextColor.initial}>
          {t('dup_instructions_title')}
        </Text>
        {type !== DupApplicationType.AOA && (
          <Link to="https://vimeo.com/645372425" target={LinkTarget.blank} isExternal>
            <Row gap={0.5} alignItems="center">
              <Text color="primary" isBold variant={TextVariant.small}>
                {t('dup_instructions_how_to')}
              </Text>
              <Icon icon="launch" color="primary" />
            </Row>
          </Link>
        )}
      </Row>
      <Gap height={2} />
      <TextContainer type={type}>
        <Row columns={2} gap={3}>
          <Row.Col size={1}>
            <Card border alignContent="center">
              <Icon icon="icon-cloud-upload" />
              <div
                dangerouslySetInnerHTML={{ __html: getTextInstructions(t, property) }}
                className="alignLeft"
              />
            </Card>
          </Row.Col>
          <Row.Col size={1} className="items" data-type={type}>
            <Card background="blue">
              <List type={ListTypes.UNORDERED} items={listItems} />
            </Card>
          </Row.Col>
        </Row>
      </TextContainer>
    </Container>
  );
};

const getTextInstructions = (
  t: (key: ITextLangKeys, ...args: string[]) => string,
  property?: Pick<SessionProperty, 'id' | 'bankStatement' | 'paystub'>
) => {
  if (!property) return '';

  const { bankStatement, paystub } = property;
  let text = t('dup_instructions_without_amount');

  if (bankStatement || paystub) {
    text = t('dup_instructions_with_amount');
    text += '<ul>';
    if (bankStatement) {
      const amountStr = bankStatement.toString();
      const message =
        bankStatement > 1
          ? t('dup_instructions_most_recents', amountStr, t('dup_document_type_bank_statements'))
          : t('dup_instructions_most_recent', amountStr, t('dup_document_type_bank_statement'));
      text += `<li>${message}</li>`;
    }
    if (paystub) {
      const amountStr = paystub.toString();
      const message =
        paystub > 1
          ? t('dup_instructions_most_recents', amountStr, t('dup_document_type_paystubs'))
          : t('dup_instructions_most_recent', amountStr, t('dup_document_type_paystub'));
      text += `<li>${message}</li>`;
    }
    text += '</ul>';
  }

  return text;
};

export default UploadInstructions;
