/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import Button, { ButtonColor, ButtonVariant } from 'src/components/atoms/Button';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import Modal, { ModalVariant } from 'src/components/organisms/Modal';
import useLanguage from 'src/context/Language/useLanguage';
import getDocumentTypes from 'src/features/DUP/helpers/getDocumentTypes';
import { FILES_TO_UPLOAD } from 'src/features/DUP/proofs/constants';
import { makeRequest } from 'src/hooks/useResource';
import { DupApplicationType, Proof, UnauthenticateSessionProofType } from 'src/types/api';
import ShowContent from './ShowContent';
import {
  Body,
  Buttons,
  Container,
  EditButtonContainer,
  Footer,
  Selector,
  SelectorContainer,
  SelectorItem
} from './styles';

export type ProofForBox = Pick<Proof, 'id' | 'jobs_error' | 'type'> & {
  // True if this is a placeholder replacing an uploaded proof
  isReplacing?: boolean;
  // True if this is a placeholder while we're uploading
  isLoading?: boolean;
  fileName?: string;
  thumb?: string;
};

export type BoxProofProps = {
  proof: ProofForBox;
  num: number;
  type: DupApplicationType;
  isLoading?: boolean;
  refresh?: () => Promise<Proof[]>;
  onRemoveFailedDoc?: () => void;
  onReplaceWithFile?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const BoxProof: React.FC<BoxProofProps> = ({
  proof,
  num,
  type,
  isLoading,
  refresh,
  onRemoveFailedDoc,
  onReplaceWithFile
}) => {
  const { translate: t } = useLanguage();
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [isRemoving, setIsRemoving] = React.useState<boolean>(false);
  const [isShowSelector, setIsShowSelector] = useState(false);

  const getNameType = (type?: string) => {
    const existType = getDocumentTypes(t).filter((types) => types.value === type);
    if (existType.length > 0) return existType[0].label;
    return type || t('dup_proof_processing');
  };

  const handleDeleteClick = () =>
    proof.jobs_error?.length ? onRemoveFailedDoc?.() : setIsModalOpen(true);

  const onUpdateProofType = async (id: string, type: string) => {
    await makeRequest(`/session/documents/${id}`, 'PUT', { type });
    await refresh?.();
  };

  const onDeleteProof = async () => {
    setIsRemoving(true);
    await makeRequest(`/session/documents/${proof.id}`, 'DELETE');
    await refresh?.();
    setIsRemoving(false);
  };

  const TypeSelector = React.useCallback(() => {
    if (!isShowSelector) return null;

    return (
      <SelectorContainer>
        <Selector onMouseLeave={() => setIsShowSelector(false)}>
          {getDocumentTypes(t).map((doc) => {
            return (
              <SelectorItem key={doc.value}>
                <Button
                  color={ButtonColor.primary}
                  variant={ButtonVariant.ghost}
                  isDisabled={isLoading || proof.type === doc.value}
                  name={t('dup_proof_button_edit_type')}
                  onClick={() => {
                    setTimeout(() => {
                      setIsShowSelector(false);
                    }, 100);
                    onUpdateProofType(proof.id, doc.value as UnauthenticateSessionProofType);
                  }}
                >
                  {doc.label}
                </Button>
              </SelectorItem>
            );
          })}
        </Selector>
      </SelectorContainer>
    );
  }, [isShowSelector]);

  return (
    <Container key={`proof_${proof.id}`}>
      <Body hasError={!!proof.jobs_error?.length}>
        <ShowContent proof={proof} isPolling={!!isLoading} />
      </Body>
      <Footer>
        <Text color={TextColor.initial} variant={TextVariant.normal}>{`${t(
          'dup_proof_name_doc'
        )} #${num}`}</Text>
        <Text color={TextColor.initial} variant={TextVariant.normal}>{`${getNameType(
          proof.type
        )}`}</Text>
      </Footer>
      <Buttons type={type}>
        <Button
          onClick={handleDeleteClick}
          color={ButtonColor.primary}
          variant={ButtonVariant.ghost}
          name={t('dup_proof_button_delete')}
          isDisabled={isLoading}
        >
          {t('dup_proof_button_delete')}
        </Button>
        <EditButtonContainer>
          <Button
            onClick={() => setIsShowSelector(true)}
            color={ButtonColor.primary}
            variant={ButtonVariant.ghost}
            name={t('dup_proof_button_edit_type')}
            isDisabled={!!proof.jobs_error?.length || isLoading}
          >
            {t('dup_proof_button_edit_type')}
          </Button>
          <TypeSelector />
        </EditButtonContainer>
        <input
          type="file"
          name={`file_reupload_${proof.id}`}
          id={`file_reupload_${proof.id}`}
          className="inputFile"
          accept={FILES_TO_UPLOAD.ALLOW_FORMAT}
          disabled={isLoading}
          onChange={onReplaceWithFile}
        />
        <label htmlFor={`file_reupload_${proof.id}`} className="inputFileButton">
          <Text color={TextColor.primary}>{t('dup_proof_button_reupload')}</Text>
        </label>
      </Buttons>
      {isModalOpen && (
        <Modal
          title={t('dup_proof_delete_title')}
          labelButtonCancel={t('dup_proof_delete_button_cancel')}
          labelButtonConfirm={
            isRemoving ? t('dup_proof_delete_is_removing') : t('dup_proof_delete_button_confirm')
          }
          showModal={isModalOpen}
          setShowModal={setIsModalOpen}
          variant={ModalVariant.danger}
          isLoadingButtonConfirm={isRemoving}
          isDisabledButtonConfirm={isRemoving}
          isDisabledButtonCancel={isRemoving}
          isBackClosable={false}
          isEscapeClosable={false}
          onConfirm={onDeleteProof}
          onCancel={() => setIsModalOpen(false)}
        >
          <Text>{t('dup_proof_delete_message')}</Text>
        </Modal>
      )}
    </Container>
  );
};

export default BoxProof;
