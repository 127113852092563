import { ThemeAttributes } from 'src/types/styled-components';
import styled, { css } from 'styled-components/macro';
import { TextColor } from '../Text';

const getBorder = (hasError: boolean) => {
  if (hasError) {
    return css`
      border: 0.125rem solid ${({ theme }) => theme.color.red[700]};
    `;
  }

  return css`
    border: 0.125rem solid ${({ theme }) => theme.color.gray[200]};

    &[data-focused='true'] {
      border-color: ${({ theme }) => theme.color.blue[700]};

      div:nth-of-type(1) {
        small {
          color: ${({ theme }) => theme.color.blue[700]};
        }
      }
    }

    &[aria-disabled='true'] {
      border-color: ${({ theme }) => theme.color.gray[200]};
    }
  `;
};

const getBackground = (isDisabled?: boolean) => {
  if (isDisabled) {
    return css`
      background: ${({ theme }) => theme.color.gray[50]};
    `;
  }

  return css`
    background: ${({ theme }) => theme.color.white};
  `;
};

export const Container = styled.div`
  position: relative;
`;

interface ContainerProps {
  theme: ThemeAttributes;
  hasError: boolean;
  id: string;
  isDisabled: boolean;
  className: string;
  endAndorment: boolean;
  multiline?: boolean;
  readOnlyColor?: keyof typeof TextColor;
}

interface OptionalLabelProps {
  hasError: boolean;
}

const andormentStyles = (props: ContainerProps) => {
  const { endAndorment, isDisabled } = props;

  if (!endAndorment) {
    return css``;
  }

  return css`
    padding-right: 2rem;

    & > .end-andorment {
      position: absolute;
      top: 9px;
      right: 0px;
      cursor: ${!isDisabled ? 'pointer' : 'initial'};

      border: none;
      background: #fff;
      padding: 0px 6px;
      margin: 0;
      height: auto;

      &:hover {
        border: none;
      }

      svg {
        width: 0.875rem;
        height: 1.5rem;
      }
    }
  `;
};

const getStyles = (multiline?: boolean, readOnlyColor?: keyof typeof TextColor) => {
  const baseStyles = css`
    border: none;
    width: 100%;
    background: ${({ theme }) => theme.color.white};
    ${({ theme }) => theme.font.normal};
    color: ${({ theme }) => theme.font.colors['initial'].color};

    &:focus {
      outline: none;
    }

    &:disabled {
      background: ${({ theme }) => theme.color.gray[50]};
    }

    &:read-only {
      color: ${({ theme }) =>
        readOnlyColor ? theme.font.colors[readOnlyColor].color : theme.color.gray[300]};
    }
  `;

  if (multiline) {
    return css`
      textarea {
        ${baseStyles};

        resize: none;
      }
    `;
  }

  return css`
    input {
      ${baseStyles};

      height: 2.125rem;
    }

    ${andormentStyles}
  `;
};

export const InputContainer = styled.div<ContainerProps>`
  position: relative;
  padding: 0.313rem;
  border-radius: 0.25rem;
  margin-bottom: 1.7rem;

  ${({ hasError }) => getBorder(hasError)};
  ${({ isDisabled }) => getBackground(isDisabled)};

  transition: border-color 0.3s ease;

  ${({ multiline, readOnlyColor }) => getStyles(multiline, readOnlyColor)};

  input:read-only {
    &[data-isclickable='true'] {
      cursor: pointer;
    }
  }
`;

export const HelperText = styled.div`
  margin-top: -1.4rem;
  margin-bottom: 0.8rem;
  margin-left: 0.5rem;

  span,
  small {
    line-height: 0.8rem;
  }
`;

export const FLoatLabel = styled.div`
  position: absolute;
  top: -0.7rem;
  background: #fff;
  padding-left: 0.15rem;
  padding-right: 0.18rem;

  small {
    color: ${({ theme }) => theme.color.gray[700]};
  }

  &[aria-invalid='true'] {
    small {
      color: ${({ theme }) => theme.color.red[700]};
    }
  }
`;

export const HelperTextOptional = styled(HelperText)<OptionalLabelProps>`
  margin-bottom: 1.2rem;
  padding-top: ${({ hasError }) => (hasError ? '1rem' : '0.5rem')};
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 0.7rem;
  right: 0.5rem;
  cursor: pointer;

  svg {
    width: 1rem;
    height: 1.5rem;
  }

  & div > div {
    display: flex;
  }
`;

export const RequiredIndicator = styled.span`
  color: ${({ theme }) => theme.redesignColor.snappt[400]};
  margin-left: 0.2rem;
`;
