import React from 'react';

export function useDebouncedCall<T>(
  callback: (value: T, timer: React.MutableRefObject<number | null>) => void,
  delay = 500
) {
  const timerRef = React.useRef<number | null>(null);

  const fireFunc = React.useMemo(
    () => async (value: T) => {
      if (timerRef.current) window.clearTimeout(timerRef.current);
      timerRef.current = window.setTimeout(async () => callback(value, timerRef), delay);
    },
    [callback, delay]
  );

  return fireFunc;
}
