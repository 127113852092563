import { Icon as Logo } from 'src/components/atoms/Icon';
import Text, { TextAlign, TextColor, TextVariant } from 'src/components/atoms/Text';
import { LanguageSelector } from 'src/components/organisms/LanguageSelector';
import { Information } from 'src/features/DUP/helpers/getInformation';
import { DupApplicationType } from 'src/types/api';
import { Container, Content } from './styles';

export type HeaderProps = {
  type: DupApplicationType;
  information: Information;
  onSetLanguage: (lang: 'EN' | 'ES', type: DupApplicationType) => void;
};

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { type, information, onSetLanguage } = props;

  return (
    <Container type={type}>
      <Content>
        <Logo icon="logo-dup" />
        <Text color={TextColor.initial} variant={TextVariant.h3} align={TextAlign.center} isBold>
          {information.title}
        </Text>
        <LanguageSelector type={type} onSetLanguage={onSetLanguage} />
      </Content>
    </Container>
  );
};

export default Header;
