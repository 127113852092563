import { useEffect, useState } from 'react';
import { Icon } from '../Icon';
import { Check, Container } from './styles';
import { CheckboxData, CheckboxProps, IconCheckboxProps } from './types';

const IconCheckbox = ({ isChecked, color, value }: IconCheckboxProps) => {
  const checkedStr = isChecked ? 'checked' : 'unchecked';
  return (
    <span id={`icon-${value}`} className={`${checkedStr} ${color}`}>
      <Icon icon={`checkbox-${checkedStr}`} />
    </span>
  );
};

const IconCheck = ({ isDisabled, isNull, label, isChecked, value }: CheckboxData) => {
  if (isDisabled) {
    return <IconCheckbox isChecked={isChecked} value={value} color="disabled" />;
  }

  if (isNull === true) {
    return (
      <span className="nullChecked" id={label}>
        <Icon icon="checkbox-null" />
      </span>
    );
  }

  return <IconCheckbox isChecked={isChecked} value={value} color="active" />;
};

const Checkbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
  const { name, showLabel, onClick, value, label, isChecked, isDisabled, isNull } = props;

  const [checkBoxData, setCheckBoxData] = useState<CheckboxData>({
    value,
    label,
    isChecked,
    isDisabled,
    isNull
  });

  useEffect(() => {
    setCheckBoxData({ ...checkBoxData, isChecked, isDisabled, isNull });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked, isDisabled, isNull]);

  const onClicking = (check: CheckboxData) => {
    const checkBox = { ...check, isChecked: !check.isChecked };
    setCheckBoxData(checkBox);
    onClick?.(checkBox);
  };

  return (
    <Container>
      <Check
        type="button"
        className="checkbox"
        name={name}
        id={checkBoxData.value}
        onClick={() => onClicking(checkBoxData)}
        disabled={!!checkBoxData.isDisabled}
        aria-pressed={checkBoxData.isChecked}
        aria-label={checkBoxData.label}
      >
        <IconCheck
          isDisabled={checkBoxData.isDisabled}
          isChecked={checkBoxData.isChecked}
          isNull={checkBoxData.isNull}
          label={checkBoxData.label}
          value={checkBoxData.value}
        />
        {showLabel && <span className="label label-checkbox">{label}</span>}
      </Check>
    </Container>
  );
};

export default Checkbox;
