import { SnackbarKey } from 'notistack';
import { useState } from 'react';
import {
  SnackbarInnerComponent,
  SnackTypes,
  useSnackbar,
  VariantType,
  VariantTypes
} from '../components/molecules/SnackbarProvider';

export const useSnackbarProvider = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [variantSnackbar, setVariantSnackbar] = useState(VariantType.error);

  const showSnackbar = (
    variant: VariantTypes,
    message: string,
    type?: SnackTypes,
    callback?: () => void,
    options?: Record<string, unknown>
  ) => {
    setVariantSnackbar(variant);
    enqueueSnackbar(message, {
      variant,
      style: { whiteSpace: 'pre-line' },
      ...options,
      action: (key: SnackbarKey) =>
        SnackbarInnerComponent({ key, variant, closeSnackbar, type, callback })
    });
  };

  return { showSnackbar, variantSnackbar, SnackTypes, VariantType };
};
