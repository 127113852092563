import { DEFAULT, LANGUAGES } from './config';

export type ILanguage = keyof typeof LANGUAGES;

export type StateData = {
  language: ILanguage;
};

export type LangStateProps = {
  children: React.ReactNode;
};

export type ITextKeys = keyof (typeof LANGUAGES)[typeof DEFAULT];

export const TextKeys = Object.keys(LANGUAGES[DEFAULT]) as ITextKeys[];

export type ContextProps = {
  state: StateData;
  dispatch: {
    setLanguage: (lang: ILanguage) => void;
    translate: (key: ITextKeys, ...args: string[]) => string;
  };
};
