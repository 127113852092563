import { useCallback } from 'react';
import { Icon } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import { ProofForBox } from 'src/components/DUP/molecules/BoxProof/BoxProof';
import useLanguage from 'src/context/Language/useLanguage';
import getProofErrorMessage, {
  PROOF_ERROR_KEYS
} from 'src/features/DUP/helpers/getProofErrorMessage';
import { UnauthenticateSessionProofType } from 'src/types/api';
import { FileContainer, FileTextRow, StyledTrigger } from './styles';

export const DocumentRow = ({
  proof,
  rowNumber,
  type,
  onDeleteProof
}: {
  type: UnauthenticateSessionProofType;
  proof: ProofForBox;
  rowNumber: number;
  onDeleteProof: (proofId: string, proofIndex: number) => Promise<void>;
}) => {
  const { translate: t } = useLanguage();

  const handleProofDelete = useCallback(async () => {
    await onDeleteProof(proof.id, rowNumber - 1);
  }, [onDeleteProof, proof.id, rowNumber]);

  const documentNameFallback =
    type === UnauthenticateSessionProofType.Paystub
      ? t('dup_document_type_paystub')
      : t('dup_document_type_bank_statement');

  const error = proof?.jobs_error?.[0];

  return (
    <FileContainer
      key={proof.id}
      justify="space-between"
      alignItems="center"
      error={Boolean(error)}
    >
      <Row alignItems="center" gap={0.5}>
        <Icon icon="file_grey" />
        <FileTextRow wrap="wrap">
          <span>{proof.fileName || `${documentNameFallback}#${rowNumber}`} </span>
          {Boolean(error) && (
            <span
              dangerouslySetInnerHTML={{
                __html: getProofErrorMessage(error as keyof typeof PROOF_ERROR_KEYS, t)
              }}
            />
          )}
        </FileTextRow>
      </Row>
      <StyledTrigger onClick={handleProofDelete} disabled={Boolean(proof.isLoading)}>
        {' '}
        <Icon icon="delete_outlined" />
      </StyledTrigger>
    </FileContainer>
  );
};
