import { Row } from 'src/components/atoms/Row';
import { StyledText } from 'src/pages/DUPPage/styles';
import styled from 'styled-components';

export const CenteredColumn = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ gap }) => (gap ? `${gap}rem` : 'unset')};
`;

export const Container = styled(CenteredColumn)`
  flex: 1;
  width: 100%;
  gap: 1rem;
`;

export const HelpText = styled(StyledText)`
  width: 100%;
  color: ${({ theme }) => theme.redesignColor.gray[700]};
  font-size: 14px;
  margin-top: 0.5rem;

  & a {
    color: ${({ theme }) => theme.redesignColor.snappt[700]};
    cursor: pointer;
    margin-left: 4px;
    font-weight: 600;
    text-decoration: none;
  }
`;

export const ButtonsContainer = styled(Row)`
  width: 100%;
  margin-top: 1.5rem;
  @media ${({ theme }) => theme.device.mobile} {
    margin-top: 1rem;
    flex-direction: row !important;
  }
`;
