import { datadogLogs } from '@datadog/browser-logs';
import { envVars } from 'src/constants/config';
import { getHostNameFromURL } from 'src/helpers';

export const datadogInit = () => {
  let env = envVars.development.env;

  let dataDogToken = envVars.development.data_dog.token;
  const service = window.location.hostname;

  switch (service) {
    case getHostNameFromURL(envVars.ralph.frontend_url):
    case getHostNameFromURL(envVars.ralph.document_portal):
      env = envVars.ralph.env;
      dataDogToken = envVars.ralph.data_dog.token;
      break;
    case getHostNameFromURL(envVars.demo.frontend_url):
    case getHostNameFromURL(envVars.demo.document_portal):
      env = envVars.demo.env;
      dataDogToken = envVars.demo.data_dog.token;
      break;
    case getHostNameFromURL(envVars.sandbox.frontend_url):
    case getHostNameFromURL(envVars.sandbox.document_portal):
      env = envVars.sandbox.env;
      dataDogToken = envVars.sandbox.data_dog.token;
      break;
    case getHostNameFromURL(envVars.production.frontend_url):
    case getHostNameFromURL(envVars.production.document_portal):
      env = envVars.production.env;
      dataDogToken = envVars.production.data_dog.token;
      break;
  }

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const pkg = require('../../package.json');
  const { version } = pkg;

  datadogLogs.init({
    site: 'datadoghq.com',
    service,
    env,
    clientToken: dataDogToken,
    version,
    forwardErrorsToLogs: true,
    sampleRate: 100
  });
  return datadogLogs;
};
