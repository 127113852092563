export const colors = {
  snappt: {
    700: '#577DB1',
    500: '#6A93CA',
    100: '#8FAED8',
    50: '#ebf1ff'
  },
  snappt_teal: '#008FE2',
  white: '#fff',
  black: {
    normal: '#1A202C',
    light: '#333',
    black: '#000'
  },
  gray: {
    10: '#ABB7C7',
    50: '#F9F9F9',
    90: '#E5E5E5',
    100: '#F3F4F6',
    200: '#E2E8F0',
    300: '#A0AEC0',
    500: '#4A5568',
    600: '#718096',
    700: `#364152`,
    900: '#1A202C'
  },
  green: {
    700: '#047857',
    100: '#D1FAE5'
  },
  red: {
    700: '#C53030',
    600: '#D84343',
    100: '#FEE2E2'
  },
  yellow: {
    700: '#F59E0B',
    500: '#B45309',
    100: '#FEF3C7'
  },
  blue: {
    50: '#F7FAFC',
    70: '#F4F9FF',
    100: '#DBEAFE',
    200: '#BFDBFE',
    300: '#EBF8FF',
    600: '#2563EB',
    700: '#1D4ED8',
    900: '#1E3A8A'
  },
  purple: {
    100: '#f5f3ff',
    700: '#7C3AED',
    500: '#e6eaf4'
  }
};

export const fonts = {
  normal: 'Roboto',
  bold: 'RobotoBold'
};

export const redesignColors = {
  snappt: {
    900: '#00395A',
    700: '#0067A3',
    600: '#007FC9',
    500: '#008FE2',
    400: '#009FFB',
    100: '#AADFFE',
    50: '#D9F1FE',
    25: '#F2FAFF'
  },
  white: '#fff',
  black: {
    normal: '#1A202C',
    100: '#012c57',
    400: '#4b5565',
    light: '#333',
    black: '#000'
  },
  gray: {
    25: '#ABB7C7',
    50: '#F8FAFC',
    90: '#D9D9D9',
    100: '#EEF2F6',
    200: '#E3E8EF',
    300: '#CDD5DF',
    400: '#9AA4B2',
    500: '#697586',
    600: '#4B5565',
    700: '#364152',
    800: '#202939',
    900: '#121926'
  },
  green: {
    700: '#047857',
    100: '#D1FAE5'
  },
  red: {
    800: '#CC3D4A',
    700: '#C53030',
    600: '#D92D20',
    100: '#FEE2E2'
  },
  yellow: {
    700: '#F59E0B',
    500: '#B45309',
    100: '#FEF3C7'
  },
  blue: {
    50: '#F7FAFC',
    70: '#F4F9FF',
    100: '#DBEAFE',
    200: '#BFDBFE',
    300: '#EBF8FF',
    600: '#2563EB',
    700: '#1D4ED8',
    900: '#1E3A8A'
  },
  purple: {
    100: '#f5f3ff',
    700: '#7C3AED',
    500: '#e6eaf4'
  }
};

export const redesignFonts = {
  normal: 'Montserrat',
  bold: 'MontserratBold'
};

export const devices = {
  mobile: `(min-device-width: 320px) and (max-device-width: 900px)`,
  xs: `(max-width: 480px)`,
  tablet: `(max-width: 768px)`,
  laptop: `(max-width: 1024px)`,
  desktop: `(max-width: 2560px)`
};
