import React from 'react';
import { Body } from 'src/components/DUP/atoms/Body';
import { Header } from 'src/components/DUP/atoms/Header';
import { Footer } from 'src/components/DUP/molecules/Footer';
import { FormApplicant } from 'src/components/DUP/molecules/FormApplicant';
import { UploadFileSection } from 'src/components/DUP/molecules/UploadFileSection';
import { UploadInstructions } from 'src/components/DUP/molecules/UploadInstructions';
import { Line } from 'src/components/atoms/Line';
import { Loader } from 'src/components/atoms/Loader';
import Section from 'src/components/atoms/Section/Section';
import { ResultMessage } from 'src/components/molecules/ResultMessage';
import { ResultMessageType } from 'src/components/molecules/ResultMessage/ResultMessage';
import useLanguage from 'src/context/Language/useLanguage';
import getInfo from 'src/features/DUP/helpers/getInformation';
import { makeRequest, useResource } from 'src/hooks/useResource';
import { useSnackbarProvider } from 'src/hooks/useSnackbarProvider';
import {
  ApplicationSubmitResponse,
  DupApplicationType,
  Proof,
  SessionApplication,
  SessionProperty
} from 'src/types/api';

export const DUPPage: React.FC<{
  type: DupApplicationType;
  application: SessionApplication;
  onUpdateApplication: (
    updates: Partial<SessionApplication>
  ) => Promise<SessionApplication | { error: string }>;
}> = ({ type, application, onUpdateApplication }) => {
  const { showSnackbar, SnackTypes, VariantType } = useSnackbarProvider();
  const { translate: t, setLanguage } = useLanguage();
  const information = getInfo(type, t);

  const [property] = useResource<SessionProperty | { error: string }>(`/session/property`);
  const [proofs = [], { refresh }] = useResource<Proof[]>(`/session/documents`);

  const [isFormValid, setIsFormValid] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [proofIsProcessing, setProofIsProcessing] = React.useState(false);

  const onSubmit = async () => {
    const response = await makeRequest<ApplicationSubmitResponse>(`/session/submit`, 'POST');
    if ('error' in response) {
      showSnackbar(VariantType.error, t('dup_submission_error_title'), SnackTypes.none);
    } else if ('applicantId' in response) {
      setIsSubmitted(true);
    }
  };

  if ('error' in application || (!!property && 'error' in property)) {
    return (
      <Section align="center">
        <ResultMessage
          type={ResultMessageType.error}
          title={t('dup_submission_error_title')}
          message={t('dup_submission_error_message')}
        />
      </Section>
    );
  }

  if (isSubmitted) {
    return (
      <Section align="center">
        <ResultMessage
          type={ResultMessageType.success}
          title={t('dup_submission_success_title')}
          message={t('dup_submission_success_message')}
        />
      </Section>
    );
  }

  if (!property) {
    return <Loader isFixed />;
  }

  return (
    <div>
      <Header information={information} type={type} onSetLanguage={setLanguage} />
      <Body>
        <FormApplicant
          type={type}
          information={information}
          property={property}
          application={application}
          setIsFormValid={setIsFormValid}
          onUpdateApplication={onUpdateApplication}
        />
        <Line />
        <UploadInstructions type={type} information={information} property={property} />
        <UploadFileSection
          type={type}
          proofs={proofs}
          refresh={refresh}
          setProofIsProcessing={setProofIsProcessing}
        />
        <Footer
          information={information}
          isSubmittable={isFormValid && !!proofs.length}
          proofIsProcessing={proofIsProcessing}
          isProofsHasErrors={proofs.some((p) => !!p.jobs_error?.length)}
          onSubmit={onSubmit}
        />
      </Body>
    </div>
  );
};
