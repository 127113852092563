import { ButtonVariant } from 'src/components/atoms/Button';
import Gap from 'src/components/atoms/Gap';
import { Loader } from 'src/components/atoms/Loader';
import { TextColor, TextVariant } from 'src/components/atoms/Text';
import {
  AccountLogoImage,
  AccountName,
  ConnectedAccount,
  ConnectedAccounts,
  DisconnectLink,
  TileContainer
} from 'src/components/DUP/molecules/WizardConnectedPayrollSection/styles';
import useLanguage from 'src/context/Language/useLanguage';
import { useArgyleLink } from 'src/hooks/useArgyleLink';
import { StyledButton, StyledText } from 'src/pages/DUPPage/styles';
import { StyledTag } from './styles';

export const ConnectedPayrollTile = () => {
  const { translate: t } = useLanguage();

  const {
    connectArgyle,
    accountsConnected,
    isLoadingAccounts,
    disconnectAccount,
    isDeletingAccounts
  } = useArgyleLink();

  const showLoadingIndicator = isLoadingAccounts || isDeletingAccounts;

  const connectClicked = () => {
    connectArgyle();
  };

  return (
    <>
      <TileContainer>
        {accountsConnected !== null && accountsConnected?.length === 0 && (
          <StyledTag> {t('dup_wizard_recommended')}</StyledTag>
        )}
        <StyledText variant={TextVariant.h3} isBold={true}>
          {t('dup_wizard_connect_payroll')}
        </StyledText>
        <StyledText variant={TextVariant.small} color={TextColor.gray500}>
          {t('dup_wizard_connect_payroll_description')}
        </StyledText>
        {!isDeletingAccounts &&
          !isLoadingAccounts &&
          accountsConnected !== null &&
          accountsConnected?.length > 0 && (
            <ConnectedAccounts>
              {accountsConnected.map((connectedAccount) => (
                <ConnectedAccount key={connectedAccount.accountId}>
                  <AccountLogoImage alt={connectedAccount.name} src={connectedAccount.logoUrl} />
                  <AccountName variant={TextVariant.small}>{connectedAccount.name}</AccountName>
                  <DisconnectLink onClick={() => disconnectAccount(connectedAccount.accountId)}>
                    {t('dup_wizard_disconnect_account')}
                  </DisconnectLink>
                </ConnectedAccount>
              ))}
            </ConnectedAccounts>
          )}
        <Gap height={0.5} />
        <StyledButton name="connects" onClick={connectClicked} variant={ButtonVariant.contained}>
          {t('dup_button_label_connect')}
        </StyledButton>
      </TileContainer>
      {showLoadingIndicator && <Loader isFixed />}
    </>
  );
};

export default ConnectedPayrollTile;
