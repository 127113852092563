import { FormProvider } from 'react-hook-form';
import Gap from 'src/components/atoms/Gap';
import Row from 'src/components/atoms/Row/Row';
import Text, { TextColor, TextVariant } from 'src/components/atoms/Text';
import useLanguage from 'src/context/Language/useLanguage';
import { FormApplicantProps, useFormApplicant } from 'src/hooks/useFormApplicant';
import { DupApplicationType } from 'src/types/api';
import { Container } from './styles';

const FormApplicant = (props: FormApplicantProps) => {
  const { translate: t } = useLanguage();
  const { type, information } = props;
  const {
    form,
    PropertyNameInput,
    UnitInput,
    NotificationEmailInput,
    FirstNameInput,
    MiddleInitialInput,
    LastNameInput,
    EmailInput,
    PhoneInput,
    HavePrevSubmitRow
  } = useFormApplicant(props);

  return (
    <FormProvider {...form}>
      <Container>
        <Row>
          <Text variant={TextVariant.h3} color={TextColor.initial}>
            {information.title_form}
          </Text>
        </Row>
        <Gap height={2} />
        {type !== DupApplicationType.AOA && (
          <Row gap={1}>
            <Row.Col size={2}>{PropertyNameInput}</Row.Col>
            {UnitInput && <Row.Col size={1}>{UnitInput}</Row.Col>}
            {NotificationEmailInput && <Row.Col size={1}>{NotificationEmailInput}</Row.Col>}
          </Row>
        )}
        <Gap height={0.5} />
        {type !== DupApplicationType.AOA && (
          <>
            <Row>
              <Text variant={TextVariant.h4} color={TextColor.initial}>
                {t('dup_form_title')}
              </Text>
            </Row>
            <Gap height={1.2} />
          </>
        )}
        <Row gap={1}>
          <Row.Col size={1}>{FirstNameInput}</Row.Col>
          <Row.Col size={0.51}>{MiddleInitialInput}</Row.Col>
          <Row.Col size={1}>{LastNameInput}</Row.Col>
          <Row.Col size={1}>{EmailInput}</Row.Col>
          {PhoneInput && <Row.Col size={1}>{PhoneInput}</Row.Col>}
        </Row>
        {HavePrevSubmitRow}
      </Container>
    </FormProvider>
  );
};

export default FormApplicant;
