import { useEffect, useRef } from 'react';
import { InnerContainer, OuterContainer } from 'src/components/DUP/atoms/WizardBody/styles';

export type BodyProps = {
  children: React.ReactNode;
  activeStep: number;
};

const Body: React.FC<BodyProps> = (props: BodyProps) => {
  const { children, activeStep } = props;

  const topRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollTop = 0;
    }
  }, [activeStep]);

  return (
    <OuterContainer ref={topRef}>
      <InnerContainer>{children}</InnerContainer>
    </OuterContainer>
  );
};

export default Body;
