import Text from 'src/components/atoms/Text';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  // select an img
  & img {
    @media ${({ theme }) => theme.device.mobile} {
      scale: 0.75;
    }
  }
`;

export const StyledText = styled(Text)`
  color: ${({ theme }) => theme.redesignColor.black[100]};
  text-align: center;
  font-family: Montserrat;
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: -0.8px;
  margin: auto;
  margin-bottom: 1rem;
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 36px;
  }
`;

export const StyledSubText = styled(StyledText)`
  color: ${({ theme }) => theme.redesignColor.black[400]};
  font-size: 24px;
  font-weight: 500;
  line-height: 33.6px;
  letter-spacing: -0.48px;
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 28px;
  }
`;
