/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import qs from 'query-string';
import React from 'react';
import { UNAUTHENTICATED_SESSION_TOKEN_KEY } from 'src/features/DUP';
import { configVars } from 'src/helpers';
import { useLocalStorage } from 'src/hooks/useLocalStorage';

export type Method = 'GET' | 'POST' | 'PUT' | 'DELETE';

export async function makeRequest<T>(
  path: string,
  method: Method,
  itemPayload: any = null,
  isFormData?: boolean,
  token?: string
): Promise<T> {
  const hostPath = configVars.epa_url;
  // If the session token is not provided, parse local storage for it.
  let sessionToken;
  try {
    sessionToken =
      token || JSON.parse(localStorage.getItem(UNAUTHENTICATED_SESSION_TOKEN_KEY) ?? '""');
  } catch (err) {
    // If there is an error parsing the session token, remove it.
    localStorage.removeItem(UNAUTHENTICATED_SESSION_TOKEN_KEY);
  }
  if (!sessionToken) {
    console.error('No session token found.');
  }
  const options: any = {
    credentials: 'include',
    headers: sessionToken ? { 'x-unauthenticated-session-token': sessionToken } : {},
    method
  };

  if (itemPayload) {
    options.body = isFormData ? itemPayload : JSON.stringify(itemPayload);
    options.headers = {
      ...options.headers,
      ...(isFormData ? {} : { 'content-type': 'application/json' })
    };
  }

  let response: Response | null = null;
  try {
    response = await fetch(`${hostPath}${path}`, options);
  } catch (err) {
    return { error: `${err}` } as any;
  }

  if (response.status === 502) {
    await delay(1000);
    try {
      response = await fetch(`${hostPath}${path}`, options);
    } catch (err) {
      return { error: `${err}` } as any;
    }
  }

  if (response.status === 204 && method === 'DELETE') {
    return { success: true } as any;
  }

  const text = await response.text();
  let json = { error: `${response.status} ${response.statusText}` };

  try {
    json = JSON.parse(text);
  } catch (err) {
    console.error(`${method} ${path} returned invalid JSON: ${text}`);
  }

  return json as any;
}

interface ResourceConfig {
  silent?: boolean;
  GETRequestMethod?: 'GET' | 'POST';
  tokenOverride?: string;
}

export interface ResourceOps<T, U = Partial<T>> {
  post: (v: U, opts?: { silent?: boolean }) => Promise<unknown>;
  put: (v: U, opts?: { silent?: boolean }) => Promise<unknown>;
  putItem: (item: { id: string | number } & U) => Promise<unknown>;
  delete: () => Promise<void>;
  deleteItem: (item: string | number | { id: string | number }) => Promise<void>;
  applyLocalUpdates: (v: T) => void;
  refresh: (token?: string) => Promise<T>;
}

// Wrapper around window.fetch for making API requests
//

export function useResource<T, U = Partial<T>>(
  path: string,
  query?: { [key: string]: any },
  config?: ResourceConfig
) {
  const { silent = false, GETRequestMethod = 'GET' } = config || {};
  const [state, setState] = React.useState<{ value: T; url: string } | undefined>(undefined);
  const [sessionToken] = useLocalStorage(UNAUTHENTICATED_SESSION_TOKEN_KEY, '');

  const queryStr = JSON.stringify(query);
  const url = `${path}${
    query ? (path.includes('?') ? `&${qs.stringify(query)}` : `?${qs.stringify(query)}`) : ''
  }`;
  const urlRef = React.useRef(url);
  urlRef.current = url;

  const getValue = React.useCallback(
    async (token?: string) => {
      return await makeRequest<T>(
        GETRequestMethod === 'POST' ? path : url,
        GETRequestMethod,
        GETRequestMethod === 'POST' ? JSON.parse(queryStr) : undefined,
        false,
        token || sessionToken
      );
    },
    [url, path, queryStr, GETRequestMethod]
  );

  React.useEffect(() => {
    const fetch = async () => {
      const value = await getValue();
      // The URL passed to the useResource may have changed while we were fetching the
      // data, and we don't want to apply data for an OLD url!
      if (urlRef.current === url) {
        setState({ url, value });
      }
    };
    setState(undefined);
    if (path) void fetch();
  }, [path, url, getValue]);

  const ops = React.useMemo(() => {
    const result: ResourceOps<T, U> = {
      post: async (v: U) => {
        try {
          const resp = await makeRequest<U>(path, 'POST', v, false, sessionToken);

          setState((lastState) => {
            if (
              resp &&
              typeof resp === 'object' &&
              'id' in resp &&
              lastState &&
              lastState.value instanceof Array
            ) {
              return { ...lastState, value: [...lastState.value, resp] as any };
            }
            console.warn('useResource: POST update skipped, no id or state.value is not an array');
            return lastState;
          });

          if (
            resp &&
            typeof resp === 'object' &&
            'error' in resp &&
            typeof (resp as U & { error: string }).error === 'string'
          ) {
            throw Error((resp as U & { error: string }).error);
          }
          return resp;
        } catch (err) {
          console.error(err);
          throw err;
        }
      },
      put: async (v: U, opts?: { silent?: boolean }) => {
        try {
          const resp = await makeRequest<T>(path, 'PUT', v, false, sessionToken);
          setState((lastState) => {
            if (lastState) {
              if (lastState.value instanceof Array) {
                if (resp instanceof Array) {
                  return { ...lastState, value: resp };
                }
              } else if (resp && typeof resp === 'object' && 'id' in resp) {
                return { ...lastState, value: Object.assign({}, lastState.value, resp) };
              }

              console.warn('useResource: PUT update skipped, response not a model or array');
              return lastState;
            }
          });
          if (
            resp &&
            typeof resp === 'object' &&
            'error' in resp &&
            typeof (resp as T & { error: string }).error === 'string'
          ) {
            throw Error((resp as T & { error: string }).error);
          }
          return resp;
        } catch (err) {
          console.error(err);
          throw err;
        }
      },
      putItem: async (item: { id: string | number } & U) => {
        const prevState = state;

        const setNextValue = ({
          lastState,
          newItem
        }: {
          lastState?: { value: T; url: string };
          newItem: any;
        }) => {
          if (lastState && lastState.value instanceof Array) {
            const nextValue: any = [];
            for (const lastStateItem of lastState.value) {
              nextValue.push(lastStateItem.id === newItem.id ? newItem : lastStateItem);
            }
            return { ...lastState, value: nextValue };
          }
          console.warn('useResource: PUT update skipped, response does not look like array item');
          return prevState;
        };

        setState((lastState) => setNextValue({ lastState, newItem: item }));

        const resp = await makeRequest<any>(`${path}/${item.id}`, 'PUT', item, false, sessionToken);

        if (resp && typeof resp === 'object' && 'id' in resp)
          setState((lastState) => setNextValue({ lastState, newItem: { ...item, ...resp } }));

        return resp;
      },
      delete: async () => {
        await makeRequest<T>(path, 'DELETE', undefined, false, sessionToken);
      },
      deleteItem: async (item: string | number | { id: string | number }) => {
        const itemId = typeof item === 'object' && 'id' in item ? item.id : item;
        await makeRequest<T>(`${path}/${itemId}`, 'DELETE', undefined, false, sessionToken);

        setState((lastState) => {
          if (lastState && lastState.value instanceof Array) {
            return { ...lastState, value: lastState.value.filter((i) => i.id !== itemId) as any };
          }
          console.warn('useResource: DELETE update skipped, local state is not an array of items');
          return lastState;
        });
      },
      applyLocalUpdates: (v: T) => {
        setState({ url, value: v });
      },
      refresh: async (token?: string) => {
        const value = await getValue(token);
        setState({ url, value });
        return value;
      }
    };
    return result;
  }, [setState, silent, getValue, path, url]);

  // Explicitly tell TS this is a tuple of two distinct types, not an array of (T | typeof Ops)
  if (state?.url === url) {
    return [state.value, ops] as [T | undefined, ResourceOps<T, U>];
  } else {
    return [undefined, ops] as [T | undefined, ResourceOps<T, U>];
  }
}

export function delay(milliseconds: number) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}
