import React from 'react';
import Button, { ButtonVariant } from 'src/components/atoms/Button';
import { Icon, IconColor } from 'src/components/atoms/Icon';
import { Row } from 'src/components/atoms/Row';
import Text, { TextColor } from 'src/components/atoms/Text';
import useLanguage from 'src/context/Language/useLanguage';
import { DupApplicationType } from 'src/types/api';

export type LanguageSelectorProps = {
  type: DupApplicationType;
  onSetLanguage: (lang: 'EN' | 'ES', type: DupApplicationType) => void;
  iconColor?: IconColor;
  textColor?: TextColor;
  size?: 'small';
};
const LanguageSelector: React.FC<LanguageSelectorProps> = (props: LanguageSelectorProps) => {
  const {
    type,
    onSetLanguage,
    iconColor = IconColor.primary,
    textColor = TextColor.primary,
    size
  } = props;
  const { translate: t, language } = useLanguage();
  const isEnglish = language === 'EN';

  const label =
    size === 'small' ? (isEnglish ? 'ES' : 'EN') : isEnglish ? t('language_es') : t('language_en');

  return (
    <Button
      name={`LanguageSelector_${language}`}
      onClick={() => {
        onSetLanguage(isEnglish ? 'ES' : 'EN', type);
      }}
      variant={ButtonVariant.ghost}
    >
      <Row gap={0.5} alignItems="center" wrap="nowrap">
        <Text color={textColor} isBold>
          {label}
        </Text>
        <Icon icon="language" color={iconColor} />
      </Row>
    </Button>
  );
};

export default LanguageSelector;
