import { configVars } from 'src/helpers';
import * as language from 'src/i18n/en';
import { UnauthenticateSessionProofType } from 'src/types/api';

export const NAME = 'DUP';
export const PAYROLL_LINK_APPLICANT_EXPERIENCE_API_URL = `${configVars.applicant_experience_api_url}/payroll_link`;
export const AOA_PROPERTY_SLUG = 'AOA';

export const UNAUTHENTICATED_SESSION_TOKEN_KEY = 'unauthenticated_session_token';

export const URLS_SLUG = {
  leasing_team: {
    name: 'leasing_team',
    path: '/applyonbehalf/',
    link: '/application/applyonbehalf/'
  },
  applicant: { name: 'unauthenticated_user', path: '', link: '/application/apply/' }
};

export const DOCUMENT_TYPES = [
  {
    value: UnauthenticateSessionProofType.Paystub,
    label: language.default.dup_document_type_paystub
  },
  {
    value: UnauthenticateSessionProofType.BankStatement,
    label: language.default.dup_document_type_bank_statement
  }
] satisfies { value: UnauthenticateSessionProofType; label: string }[];
