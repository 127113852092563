import PropTypes from 'prop-types';
import { Icon } from '../Icon';
import { Container, SpinnerIcon } from './styles';

export enum SpinnerSize {
  big = 'big',
  normal = 'normal',
  medium = 'medium',
  small = 'small'
}

export enum SpinnerColor {
  primary = 'primary',
  secondary = 'secondary',
  cancel = 'cancel',
  disabled = 'disabled',
  white = 'white'
}

export type SpinnerProps = {
  size: keyof typeof SpinnerSize;
  color?: keyof typeof SpinnerColor;
  className?: string;
  centered?: boolean;
};

const Spinner: React.FC<SpinnerProps> = (props: SpinnerProps) => {
  const { size, color, className, centered = false } = props;

  return (
    <Container centered={centered} className={className} role="spinner">
      <SpinnerIcon color={color} size={size}>
        <Icon icon="spinner" />
      </SpinnerIcon>
    </Container>
  );
};

Spinner.propTypes = {
  size: PropTypes.oneOf<keyof typeof SpinnerSize>(Object.values(SpinnerSize)).isRequired,
  color: PropTypes.oneOf<keyof typeof SpinnerColor>(Object.values(SpinnerColor)).isRequired,
  className: PropTypes.string
};

Spinner.defaultProps = {
  size: SpinnerSize.normal,
  color: SpinnerColor.primary,
  centered: false,
  className: ''
};

export default Spinner;
