import { useState } from 'react';
import { ButtonVariant } from 'src/components/atoms/Button';
import Gap from 'src/components/atoms/Gap';
import { TextColor, TextVariant } from 'src/components/atoms/Text';
import { ModalFooter } from 'src/components/DUP/molecules/ManualPayrollTile/styles';
import { TileContainer } from 'src/components/DUP/molecules/WizardConnectedPayrollSection/styles';
import { WizardProofOfIncomeProps } from 'src/components/DUP/molecules/WizardProofOfIncome/WizardProofOfIncome';
import { useWizardUpload } from 'src/components/DUP/molecules/WizardUpload/useWizardUpload';
import Modal, { ModalVariant } from 'src/components/organisms/Modal';
import useLanguage from 'src/context/Language/useLanguage';
import { StyledButton, StyledText } from 'src/pages/DUPPage/styles';

export const ManualPayrollTile = ({
  type,
  property,
  proofs,
  refresh,
  onFinished
}: Pick<WizardProofOfIncomeProps, 'type' | 'property' | 'proofs' | 'refresh'> & {
  onFinished: () => void;
}) => {
  const { translate: t } = useLanguage();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { UploadedDocumentsSection, WizardUploadEl, instructions, proofIsProcessing } =
    useWizardUpload({
      type,
      proofs,
      refresh,
      property
    });

  return (
    <TileContainer>
      <StyledText variant={TextVariant.h3} isBold>
        {t('dup_wizard_manual_payroll')}
      </StyledText>
      <StyledText variant={TextVariant.small} color={TextColor.gray500}>
        {t('dup_wizard_manual_payroll_description')}
      </StyledText>
      {UploadedDocumentsSection}
      <Gap height={0.5} />
      <StyledButton
        name="upload file"
        onClick={() => setIsModalOpen(true)}
        variant={ButtonVariant.outline}
      >
        {t('dup_wizard_upload_file')}
      </StyledButton>
      {isModalOpen && (
        <Modal
          variant={ModalVariant.wizard}
          title={
            <StyledText variant={TextVariant.h4} isBold>
              {instructions[0]}
            </StyledText>
          }
          blurBackground
          showModal={isModalOpen}
          setShowModal={setIsModalOpen}
          showModalActions={false}
          isBackClosable={false}
          isEscapeClosable={false}
          showCloseButton
        >
          {WizardUploadEl}
          <ModalFooter>
            <StyledButton
              name="cancel upload"
              onClick={() => setIsModalOpen(false)}
              variant={ButtonVariant.outline}
            >
              Cancel
            </StyledButton>
            <StyledButton
              name="next"
              isDisabled={proofIsProcessing}
              onClick={() => {
                setIsModalOpen(false);
                onFinished();
              }}
              variant={ButtonVariant.contained}
            >
              Next
            </StyledButton>
          </ModalFooter>
        </Modal>
      )}
    </TileContainer>
  );
};
