import { Container } from './styles';

export type Props = {
  children: React.ReactNode;
};

const WizardContainer: React.FC<Props> = (props: Props) => {
  const { children } = props;
  return <Container>{children}</Container>;
};

export default WizardContainer;
