import styled, { css } from 'styled-components';

export const HideOnDesktopCSS = css`
  display: none;
  @media ${({ theme }) => theme.device.mobile} {
    display: block;
  }
`;

const HideOnDesktop = styled.div`
  ${HideOnDesktopCSS}
`;

export default HideOnDesktop;
