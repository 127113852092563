import { TextComponent } from './styles';

export enum TextVariant {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  normal = 'normal',
  small = 'small',
  big = 'big',
  span = 'span'
}

export enum TextAlign {
  left = 'left',
  center = 'center',
  right = 'right',
  justify = 'justify'
}

export enum TextAlignItems {
  normal = 'normal',
  flexStart = 'flex-start',
  flexEnd = 'flex-end',
  center = 'center',
  start = 'start',
  end = 'end',
  selfStart = 'self-start',
  selfEnd = 'self-end',
  baseline = 'baseline',
  stretch = 'stretch',
  safe = 'safe',
  unsafe = 'unsafe'
}

export enum TextColor {
  initial = 'initial',
  inherit = 'inherit',
  primary = 'primary',
  secondary = 'secondary',
  white = 'white',
  muted = 'muted',
  warning = 'warning',
  success = 'success',
  error = 'error',
  // TODO: refactor this component to not keep adding random color when we need one
  gray100 = 'gray100',
  gray200 = 'gray200',
  gray300 = 'gray300',
  gray500 = 'gray500',
  gray600 = 'gray600',
  gray700 = 'gray700'
}

export type TextProps = {
  children: React.ReactNode;
  color?: keyof typeof TextColor;
  variant?: TextVariant;
  align?: TextAlign;
  alignItems?: TextAlignItems;
  isBold?: boolean;
  isStretched?: boolean;
  className?: string;
};

const getTypeVariant = (variant?: TextVariant) => {
  switch (variant) {
    case TextVariant.normal:
      return 'p';
    case TextVariant.big:
      return 'h1';
    default:
      return variant;
  }
};

const Text: React.FC<TextProps> = (props: TextProps) => {
  const { children, color, variant, isBold, align, alignItems, isStretched, className } = props;

  return (
    <TextComponent
      isBold={isBold}
      variant={variant}
      color={color}
      align={align}
      alignItems={alignItems}
      isStretched={isStretched}
      as={getTypeVariant(variant)}
      className={className}
    >
      {children}
    </TextComponent>
  );
};

export default Text;
