import { Proof, UnauthenticateSessionProofType } from 'src/types/api';

export function isIncomeDocumentProofType(proof: Proof): proof is Proof {
  return [
    UnauthenticateSessionProofType.SocialSecurityStatement,
    UnauthenticateSessionProofType.CashAppStatement,
    UnauthenticateSessionProofType.Paystub,
    UnauthenticateSessionProofType.SocialSecurityBenefitsLetter,
    UnauthenticateSessionProofType.DepartmentOfVeteransAffairsBenefitLetter
  ].includes(proof.type);
}

export function isAdditionalDocumentProofType(proof: Proof): proof is Proof {
  return [
    UnauthenticateSessionProofType.BankStatement,
    UnauthenticateSessionProofType.CreditDebitCardStatement,
    UnauthenticateSessionProofType.UtilityBill,
    UnauthenticateSessionProofType.TaxTranscript,
    UnauthenticateSessionProofType.InvestmentAccount
  ].includes(proof.type);
}

export { default as WizardSubmit } from './WizardSubmit';
