/* eslint-disable react/no-unescaped-entities */
import { useLocation } from 'react-router-dom';
import { Card } from 'src/components/atoms/Card';
import Gap from 'src/components/atoms/Gap';
import Section from 'src/components/atoms/Section/Section';
import Text, { TextAlign, TextVariant } from 'src/components/atoms/Text';

export const PrivacyPolicyPage: React.FC = () => {
  const search = useLocation().search;
  const isAOApartner = new URLSearchParams(search).get('isAOApartner');

  return (
    <Section>
      <Card background="white" elevation={1.5} rounded>
        <Text variant={TextVariant.h2} align={TextAlign.center} isBold>
          {isAOApartner === null ? 'SNAPPT PRIVACY POLICY' : 'PRIVACY POLICY'}
        </Text>
        <Text align={TextAlign.center}>
          Effective Date:&nbsp;
          <Text isBold variant={TextVariant.span}>
            08/12/20
          </Text>
        </Text>
        <Gap height={1} />
        <Text isStretched>
          Snappt (“<b>Snappt</b>,” “<b>we</b>,” “<b>our</b>,” or “<b>us</b>”) is committed to
          protecting your privacy and the security of your information. This Privacy Policy explains
          the types of personal information we collect from our website visitors, customers, and
          other individuals when they visit our website (
          <a href="https://snappt.com/" target="_blank" rel="noopener noreferrer">
            www.SNAPPT.com
          </a>
          ) and related sites and web-based services (our “<b>Site</b>”) as well as through the use
          of Snappt’s products and services (collectively, the “<b> Services</b>”). This Policy also
          describes how we use personal information, the purpose(s) for sharing and recipients of
          personal information, and your rights and choices as to that information.
        </Text>
        <Gap height={1} />
        <Text isStretched>
          Snappt obtains personal information about you from different sources to provide and manage
          our Site and Services. “<b>You</b>” may be a visitor to Site, an organization that uses
          our Services (“<b>Customer</b>”) or an applicant of a Customer (“<b>User</b>”).
        </Text>
        <Gap height={1} />
        <Text isStretched>This Privacy Policy explains the following:</Text>
        <ul>
          <li>Collection of Personal Information</li>
          <li>Use of Personal Information</li>
          <li>Sharing of Personal Information</li>
          <li>Your Rights and Choices</li>
          <li>Links to Other Sites</li>
          <li>Children’s Privacy</li>
          <li>Data Security</li>
          <li>Ownership</li>
          <li>Keep Yourself Safe</li>
          <li>Responsible Disclosure</li>
          <li>International Data Transfers</li>
          <li>Updates to This Privacy Policy</li>
          <li>Contact Information</li>
        </ul>
        <Text variant={TextVariant.h3} align={TextAlign.left} isBold>
          1. Collection of Personal Information
        </Text>
        <Gap height={1} />
        <Text isStretched>
          Personal information is any information that relates to an identified or identifiable
          individual. Personal information does not include publicly available information or
          information that has been anonymized, de-identified, or aggregated. Personal information
          we collect will be apparent by the context of the page or your interactions with us, and
          generally includes personal information you choose to provide us and personal information
          we collect as your interact with our Sites and Services.
        </Text>
        <ol type="a">
          <li>
            <b>Personal information you provide to us </b>
          </li>
          Personal information we collect will be apparent by the context through which it is
          provided, and may include, but is not limited to, the following:
          <ul>
            <li>
              When you request a demo of our Services or otherwise contact us by providing your
              email address, name, company name, and telephone number
            </li>
            <li>
              When you communicate with us via email, phone, fax, text, or by any other means, and
              provide your personal information over the course of those communications
            </li>
            <li>
              When you otherwise provide us with your personal information in order to receive our
              Services or information relating to our Services, including as a User, Customer, or on
              behalf of a Customer
            </li>
          </ul>
          <li>
            <b>Information that we automatically collect</b>
          </li>
          Our Sites use cookies and other tracking technologies (such as web beacons, embedded
          scripts, tags) which collect information from you automatically, including:
          <ul>
            <li>
              <b>Browser and device data</b>, such as IP address, device type, operating system and
              Internet browser type, screen resolution, operating system name and version, device
              manufacturer and model, language, plug-ins, add-ons, and the language version of the
              Sites you are visiting; and
            </li>
            <li>
              <b>Usage data</b>, such as time spent on the Sites, pages visited, links clicked,
              language preferences, and the pages that led or referred you to our Sites.
            </li>
          </ul>
          We use third party analytics services on our Sites to help us analyze your use of our
          Sites and diagnose technical issues.
          <li>
            <b> Information collected from third-party sources</b>
          </li>
          <ul>
            <li>
              <b> Our service providers.</b> We may receive personal information about you from our
              service providers, such as those that conduct payment processing, analytics,
              advertising, hosting, marketing, customer and technical support, and other services.
              Please review the remainder of this Privacy Policy to learn more about how and why we
              use this information.
            </li>
            <li>
              <b> Our Customers.</b> We may receive personal information about you from our third
              party Customers, who have engaged us as their service provider to render Services to
              them. Our collection and use of this personal information is subject to any notices
              and agreements between you and the Customer.
            </li>
            <li>
              <b> Other third parties.</b> We may receive personal information about you from third
              party sources, including things that other third parties may post publicly about you
              or otherwise provide to us.
            </li>
          </ul>
        </ol>
        <Text variant={TextVariant.h3} align={TextAlign.left} isBold>
          2. Use of Personal Information
        </Text>
        <Gap height={1} />
        <Text isStretched>
          We use the personal information we collect to provide, maintain and improve our Sites and
          Services, including:
        </Text>
        <ul>
          <li>
            To enter into a contract for the sale of goods or services, to bill you for products or
            services, and to process payment transactions;
          </li>
          <li>
            To provide you with customer support, including to contact you in response to an inquiry
            that you sent and to send administrative messages, technical notices, updates, alerts
            and other information;
          </li>
          <li>
            To provide you with better products and services and improve and grow our business,
            including to perform research and development, understand our customer base and
            purchasing trends, and understand the effectiveness of our marketing; and
          </li>
          <li>To operate and maintain safe, secure and reliable Sites and Platform.</li>
        </ul>
        <Text isStretched>
          We may also use your personal information to send you promotional communications about our
          Services. You may opt out of receiving any, or all, of these communications from us via
          the unsubscribe link provided in such emails or by following further instructions provided
          in <b> Your Rights and Choices.</b>
        </Text>
        <Gap height={1} />
        <Text variant={TextVariant.h3} align={TextAlign.left} isBold>
          3. Sharing of Personal Information
        </Text>
        <Gap height={1} />
        <Text isStretched>
          Snappt may share your personal information with the following trusted entities:
        </Text>
        <ul>
          <li>
            <b>Our service providers</b>, as necessary to provide services on our behalf, such as
            website hosting, data analysis, marketing, email delivery, technical support, customer
            service, and analytics.
          </li>
          <li>
            <b>Our Customers</b> as necessary to provide, maintain, and develop our Sites and
            Services.
          </li>
          <li>
            <b> Pursuant to a business transaction</b>, subject to applicable law, in the event of a
            merger, acquisition, bankruptcy, or other sale or transfer of all or a portion of our
            assets. If any such transaction occurs, the purchaser will be entitled to use and
            disclose the personal data collected by us in the same manner that we are able to use
            it, and the purchaser will assume the rights and obligations regarding personal data as
            described in this Privacy Policy.
          </li>
          <li>
            <b>Compliance and harm prevention</b>, including to respond to subpoenas, court orders,
            or legal process, or to establish or exercise our legal rights or defend against legal
            claims. We also may provide information in order to investigate, prevent, or take action
            regarding illegal activities or wrongdoing; to protect the rights, property, or safety
            of Snappt, its users, and others; to enforce our agreements; or as otherwise required by
            law.
          </li>
        </ul>
        <Text isStretched>
          We may create anonymous or de-identified data from personal information by removing data
          components that make the data personally identifiable to you or through obfuscation or
          other means. We may analyze any data we aggregate or de- identify and share our analyses
          at our discretion. Our use of anonymized and de- identified data is not subject to this
          Privacy Policy.
        </Text>
        <Gap height={1} />
        <Text variant={TextVariant.h3} align={TextAlign.left} isBold>
          4. Your Rights and Choices
        </Text>
        <Gap height={1} />
        <Text isStretched>
          You may have rights and choices regarding our use and disclosure of your personal
          information. Unless instructed otherwise, you can exercise these rights and choices by
          emailing us at <a href="mailto:support@snappt.com"> support@snappt.com</a> or mailing us a
          letter to 6100 Wilshire Blvd, Suite 1110 Los Angeles 90028.
        </Text>
        <ol type="a">
          <li>
            <b>Opting out of receiving electronic communications from us</b>. If you no longer wish
            to receive promotional email communications from us, you may opt out via the unsubscribe
            link included in such emails or by emailing us at{' '}
            <a href="mailto:support@snappt.com"> support@snappt.com</a>. We will comply with your
            request(s) as soon as reasonably practicable. Please note that if you opt out of
            receiving promotional emails from us, we may still send you important administrative
            messages that are required in order to provide you with our Services.
          </li>
          <li>
            <b> Your California privacy rights.</b> California residents have the right to request
            information from Snappt regarding third parties to whom we have disclosed certain
            categories of personal information during the preceding year for those third parties’
            direct marketing purposes under California’s “Shine the Light” law. We do not currently
            disclose this type of personal information to third parties for their own purposes.
            However, if you are a California resident and would like to inquire further, please
            email us at <a href="mailto:support@snappt.com"> support@snappt.com</a> with “California
            Privacy Rights” as the subject line or write to us at 6100 Wilshire Blvd, Suite 1110 Los
            Angeles 90028. You must include your full name, email address and postal address in your
            request so that we can verify your California residence and respond.
            <br /> <br />
            In addition, California residents are entitled to certain consumer privacy rights
            related to their personal information under the California Consumer Privacy Act (“
            <b>CCPA</b>”). As a platform that collects personal information in order to provide our
            Sites and Services to Customers, the CCPA may apply to the personal information we hold
            about you on behalf of our Customers. If you are a User and California resident, please
            direct any CCPA-related requests directly to the relevant Customer (e.g., your property
            manager).
          </li>
          <li>
            <b>Your Nevada privacy rights</b>. Nevada residents have the right to request to opt out
            of any “sale” of their personal information under Nevada SB 220. We do not currently
            sell personal information under Nevada law. However, you may still request to opt out of
            the future sale of your personal information. If you are a Nevada resident and would
            like to make such a request, please email us at{' '}
            <a href="mailto:support@snappt.com"> support@snappt.com</a> with “Nevada Privacy Rights”
            as the subject line or write to us at 6100 Wilshire Blvd, Suite 1110 Los Angeles 90028.
            You must include your full name, email address and postal address in your request so
            that we can verify your Nevada residence and respond. In the event we sell your personal
            information after the receipt of your request, we will make reasonable efforts to comply
            with such request.
          </li>
        </ol>
        <Gap height={1} />
        <Text variant={TextVariant.h3} align={TextAlign.left} isBold>
          5. Links to Other Sites
        </Text>
        <Gap height={1} />
        <Text isStretched>
          Our Sites may contain links or otherwise provide access to other third-party sites, which
          may include websites, mobile applications, or other web-based services. Please note that
          we have no control over and are not responsible for third-party sites, their content, or
          any products or services available through the third-party sites. Our Privacy Policy does
          not apply to third-party sites. We encourage you to read the privacy policies of any
          third-party sites with which you choose to interact.
        </Text>
        <Gap height={1} />
        <Text variant={TextVariant.h3} align={TextAlign.left} isBold>
          6. Children’s Privacy
        </Text>
        <Gap height={1} />
        <Text isStretched>
          We do not knowingly collect personal information from individuals under the age of 13
          years without parental consent. No personal information should be submitted to our Sites
          by individuals under the age of 13. If we learn we have collected or received personal
          information from a child under the age of 13 without parental consent, we will delete that
          personal information as permitted by law. If you believe we may have any collected
          personal information from a child under the age of 13 without parental consent, please
          submit a written request via the email to{' '}
          <a href="mailto:support@snappt.com"> support@snappt.com</a>.
        </Text>
        <Gap height={1} />
        <Text variant={TextVariant.h3} align={TextAlign.left} isBold>
          7. Data Security
        </Text>
        <Gap height={1} />
        <Text isStretched>
          Snappt use bank-level security standards to help protect your information against
          unauthorized access, destruction, alteration, misuse, or loss. Snappt uses HTTPS to ensure
          that your data is transmitted securely.
        </Text>
        <Text isStretched>
          To protect your privacy, we limit the amount of sensitive data we store on our servers.
          For example, we do not store the contents of credit reports or background checks, or
          credit card numbers. When we do store financial information, we encrypt and protect that
          data on secure servers.
        </Text>
        <Text isStretched>
          We designed and built all our systems with multiple levels of security, and we manage them
          according to industry best practices. Our hosting provider maintains world-class
          facilities that are certified to the highest levels of physical and virtual security.
          Reputable third-party security firms perform regular internal and external security audits
          of our systems, something we consider an essential investment.
        </Text>
        <Text isStretched>
          Please note, however, that no method of transmission over the Internet or method of
          electronic storage is 100% secure.
        </Text>
        <Gap height={1} />
        <Text variant={TextVariant.h3} align={TextAlign.left} isBold>
          8. Ownership
        </Text>
        <Gap height={1} />
        <Text isStretched>
          When you use Snappt, you own and control your information. We limit access to your
          information whenever possible. Landlords must verify their identities before viewing
          credit reports and background checks, or receiving payments. All Snappt employees receive
          thorough training before accessing customer data, and we comply with the Fair Credit
          Reporting Act (FCRA) and the Gramm–Leach–Bliley Act (GLBA). We share information securely
          with trusted third parties only when needed—to provide services, prevent fraud, and meet
          legal requirements.
        </Text>
        <Gap height={1} />
        <Text variant={TextVariant.h3} align={TextAlign.left} isBold>
          9. Keep Yourself Safe
        </Text>
        <Gap height={1} />
        <Text isStretched>
          Even though we review every financial transaction for fraud, we recommend only sending
          payments to people you know. The same goes for sharing your information. If you have any
          questions or concerns about safety or security, please contact us at{' '}
          <a href="mailto:support@snappt.com"> support@snappt.com</a>.
        </Text>
        <Gap height={1} />
        <Text variant={TextVariant.h3} align={TextAlign.left} isBold>
          10. Responsible Disclosure
        </Text>
        <Gap height={1} />
        <Text isStretched>
          We take security seriously and we appreciate your help in notifying us of vulnerabilities
          in a responsible manner. If you are a security researcher and have found a potential
          security vulnerability in our systems, please send details to{' '}
          <a href="mailto:security@snappt.com"> security@snappt.com</a>.
        </Text>
        <Gap height={1} />
        <Text variant={TextVariant.h3} align={TextAlign.left} isBold>
          11. International Data Transfers
        </Text>
        <Gap height={1} />
        <Text isStretched>
          OurSite is operated in the U.S. and intended for users located in the U.S. We may
          transfer, store and use information we collect and maintain about you, including Personal
          Information outside of your state, province, country or other governmental jurisdiction.
          The data protection laws in the jurisdiction in which we process Personal Information may
          differ from those of your jurisdiction, and in certain circumstances, your personal
          information may be subject to access requests from governments, courts, law enforcement
          agencies or regulatory agencies in those other jurisdictions. By using the Site or
          providing us with any information, you consent to the transfer and processing of your
          information, including personal information, in the U.S. and elsewhere as set forth in
          this Privacy Policy.
        </Text>
        <Gap height={1} />
        <Text variant={TextVariant.h3} align={TextAlign.left} isBold>
          12. Updates to This Privacy Policy
        </Text>
        <Gap height={1} />
        <Text isStretched>
          We may update this Privacy Policy from time to time. The date this Privacy Policy was last
          updated is identified at the top of this page. Any changes are effective when we post the
          revised Privacy Policy on the Sites. If we make material changes to this Privacy Policy
          that expand our rights to collect new personal data or to use differently any personal
          data that we have previously collected about you, we will notify you and provide you with
          the disclosure by posting it on our Sites and/or via email. We encourage you to review
          this Privacy Policy often to stay informed of how we may process your personal data.
        </Text>
        <Gap height={1} />
        <Text variant={TextVariant.h3} align={TextAlign.left} isBold>
          13. Contact Us
        </Text>
        <Gap height={1} />
        <Text isStretched>
          If you would like to submit a privacy rights request as detailed above, or if you have any
          questions or concerns about this Privacy Policy or our privacy practices, please email us
          at <a href="mailto:support@snappt.com"> support@snappt.com</a>
        </Text>
      </Card>
    </Section>
  );
};
