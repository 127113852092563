/* eslint-disable react/no-unescaped-entities */
import { useLocation } from 'react-router-dom';
import { Card } from 'src/components/atoms/Card';
import Gap from 'src/components/atoms/Gap';
import Section from 'src/components/atoms/Section/Section';
import Text, { TextAlign, TextVariant } from 'src/components/atoms/Text';

export const TermsPage: React.FC = () => {
  const search = useLocation().search;
  const isAOApartner = new URLSearchParams(search).get('isAOApartner');

  return (
    <Section>
      <Card background="white" elevation={1.5} rounded>
        {isAOApartner === null && (
          <Text variant={TextVariant.h2} align={TextAlign.center} isBold>
            Snappt
          </Text>
        )}
        <Text variant={TextVariant.h4} align={TextAlign.center} isBold>
          Terms and Conditions
        </Text>
        <Text align={TextAlign.center}>
          Effective Date:&nbsp;
          <Text isBold>04/21/2022</Text>
        </Text>
        <Gap height={1} />
        <Text isBold>
          PLEASE READ THIS AGREEMENT CAREFULLY. IT SETS FORTH THE LEGALLY BINDING TERMS AND
          CONDITIONS FOR YOUR USE OF THE WEBSITE(S) (DEFINED BELOW), APP(S) (DEFINED BELOW) AND
          SERVICE(S) (DEFINED BELOW) MADE AVAILABLE BY SNAPPT. <br /> <br /> THIS AGREEMENT REQUIRES
          YOU ARBITRATE ANY DISPUTES YOU HAVE WITH US AND YOU WAIVE THE ABILITY TO BRING CLAIMS
          AGAINST US IN COURT, TO HAVE ANY DISPUTE HEARD BY A JUDGE OR JURY, OR TO BRING ANY CLAIMS
          AGAINST US IN A CLASS ACTION FORMAT.
        </Text>
        <Gap height={1} />
        <Text isStretched>
          IMPORTANT — THESE TERMS AND CONDITIONS <b>("AGREEMENT") </b> ARE A LEGAL AGREEMENT BETWEEN
          YOU (EITHER AN INDIVIDUAL OR ENTITY) (<b>"YOU"</b> or <b>"YOUR"</b>) AND SNAPPT INC.
          (HEREINAFTER "<b>COMPANY</b>", "<b>SNAPPT</b>" "<b>WE</b>", "<b>US</b>" OR "<b>OUR</b>")
          THAT SETS FORTH THE LEGAL TERMS AND CONDITIONS FOR YOU USE OF{' '}
          <a href="https://snappt.com/" target="_blank" rel="noopener noreferrer">
            WWW.SNAPPT.COM
          </a>
          &nbsp; AND ANY OTHER WEBSITE OWNED AND OPERATED BY COMPANY (THE "<b>WEBSITE(S)</b>") AND
          COMPANY'S SERVICES, INCLUDING ANY SOFTWARE, PRODUCTS OR OTHER SERVICES OFFERED BY COMPANY
          FROM TIME TO TIME AND OTHER SERVICES OFFERED THROUGH THIRD PARTIES INTEGRATING COMPANY
          FUNCTIONALITY THAT PROVIDE A LINK TO THIS AGREEMENT (COLLECTIVELY WITH THE WEBSITE(S) AND
          THE "<b>SERVICE(S)</b>
        </Text>

        <Gap height={1} />
        <Text isStretched>
          THE COMPANY PROVIDES AN ONLINE PLATFORM THAT ALLOWS ENTITIES AND INDIVIDUALS THAT OWN OR
          MANAGE RENTAL PROPERTIES (“<b>LANDLORDS</b>”) AND APPLICANTS SEEKING TO RENT SUCH RENTAL
          PROPERTIES (“<b>APPLICANTS</b>”) TO USE THE FUNCTIONALITY WE MAKE AVAILABLE TO FACILITATE
          ASPECTS OF THE RENTAL APPLICATION PROCESS AND TO HELP ENSURE DOCUMENTS PROVIDED BY
          APPLICANTS ARE VALID AND NOT FRAUDULENT IN NATURE.
        </Text>

        <Gap height={1} />

        <ol>
          <li>
            <Text isStretched>
              <u>Agreement</u>.&nbsp;&nbsp; THIS AGREEMENT APPLIES EQUALLY TO BOTH LANDLORDS AND
              APPLICANTS USE OF THE SERVICE(S) AND YOUR USE OF SERVICES(S) CONSTITUTES ACCEPTANCE OF
              THIS AGREEMENT. BY USING ANY OF OUR SERVICE(S), YOU AGREE TO THE TERMS AND CONDITIONS
              OF THIS AGREEMENT, INCLUDING, WITHOUT LIMITATION, THE ELECTRONIC DISCLOSURE AGREEMENT
              IN SECTION 5 BELOW AND THE ARBITRATION AGREEMENT IN SECTION 12 BELOW.
            </Text>

            <Gap height={1} />

            <Text isStretched>
              TO PURCHASE OR OTHERWISE GAIN ACCESS TO CERTAIN SERVICE(S), COMPANY MAY REQUIRE YOU
              (IF YOU ARE A LANDLORD) TO ENTER INTO A SEPARATE MASTER SERVICES AGREEMENT (THE “
              <b>MSA</b>
              ”). TO THE EXTENT OF A CONFLICT BETWEEN THIS AGREEMENT AND THE MSA, THE MSA SHALL
              GOVERN AND CONTROL IN ALL RESPECTS. TO THE EXTENT YOU HAVEN’T ENTERED INTO A MSA WITH
              COMPANY, INCLUDING, IF YOU ARE AN APPLICANT, THE TERMS OF THIS AGREEMENT SHALL APPLY
              TO YOUR ACCESS AND USE OF THE SERVICE(S). FURTHER, IN SOME INSTANCES, BOTH THIS
              AGREEMENT, AND SEPARATE GUIDELINES, RULES, POLICIES, OR PROCEDURES SETTING FORTH
              ADDITIONAL OR DIFFERENT TERMS AND/OR CONDITIONS WHICH WILL APPLY TO YOUR USE OF THE
              SERVICE(S) OR TO A PRODUCT OR SERVICE OFFERED VIA THE SERVICE(S) (IN EACH SUCH
              INSTANCE, AND COLLECTIVELY, “<b>ADDITIONAL TERMS</b>”). WHERE ADDITIONAL TERMS APPLY,
              WE WILL MAKE THEM AVAILABLE FOR YOU TO READ THROUGH IN CONNECTION WITH YOUR USE OF
              THAT SERVICE. BY USING THAT SERVICE, YOU AGREE TO THE ADDITIONAL TERMS. TO THE EXTENT
              THERE IS A CONFLICT BETWEEN THIS AGREEMENT AND ANY ADDITIONAL TERMS, THE ADDITIONAL
              TERMS WILL CONTROL UNLESS THE ADDITIONAL TERMS EXPRESSLY STATE OTHERWISE.
            </Text>
            <Gap height={1} />

            <Text isStretched>
              This Agreement (or if applicable Additional Terms), in the form posted at the time of
              your use of the applicable Service(s) to which it applies shall govern such use
              (including transactions entered during such use). AS OUR PLATFORM AND OUR SERVICE(S)
              EVOLVE, WE RESERVE THE RIGHT TO CHANGE THE TERMS AND CONDITIONS OF THIS AGREEMENT OR
              TO MODIFY OR DISCONTINUE THE SERVICE(S) OFFERED BY COMPANY AT ANY TIME. IF WE CHANGE
              THIS AGREEMENT, WE WILL GIVE YOU NOTICE BY POSTING THE REVISED AGREEMENT ON THE
              APPLICABLE SERVICE(S). THOSE CHANGES WILL GO INTO EFFECT ON THE EFFECTIVE DATE SHOWN
              IN THE REVISED AGREEMENT. BY CONTINUING TO USE ANY SERVICE(S) FROM COMPANY AFTER THE
              NEW EFFECTIVE DATE, YOU AGREE TO BE BOUND BY SUCH CHANGES. IF THE MODIFIED TERMS ARE
              NOT ACCEPTABLE TO YOU, YOUR ONLY RECOURSE IS TO CEASE USING THE SERVICE(S). Therefore,
              you should review the posted terms of service and any applicable Additional Terms each
              time you use the Service(s) (at least prior to each transaction or submission). The
              new terms will be effective as to new use and transactions as of the time that we post
              them, or such later date as may be specified in them or in other notice to you.
              However, the terms of service (and any applicable Additional Terms) that applied when
              you previously used the Service(s) will continue to apply to such prior use (i.e.,
              changes and additions are prospective only) unless mutually agreed. In the event any
              notice to you of new, revised or additional terms is determined by a tribunal to be
              insufficient, the prior agreement shall continue until sufficient notice to establish
              a new agreement occurs. You should frequently check the home page, and the email you
              associated with your purchases for notices, all of which you agree are reasonable
              manners of providing you notice. You can reject any new, revised or Additional Terms
              by discontinuing use of the Service(s).
            </Text>
          </li>
          <li>
            <Text isStretched>
              <u>Service Description/Account Creation/Eligibility/Disputes.</u>&nbsp;&nbsp; As a
              user of the Service(s), including either as a Landlord or Applicant, you agree to
              provide us with complete and accurate information and to update such information to
              keep it accurate, current and complete. You hereby grant Company the right to use and
              distribute the documents, information, data and materials that you have provided to
              Company for the purpose for which it was provided and as otherwise disclosed in our{' '}
              <b>
                <u>
                  <a
                    href="https://snappt.com/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </u>
              </b>
              .
            </Text>
            <Gap height={1} />
            <Text isStretched>
              In order to access or use the features and Service(s), including those available
              through our platform, you may be required to first register for an account through our
              online registration process and purchase a subscription through agreement to our MSA
              if you are a Landlord or other paid user of the Service(s). The Service(s) practices
              governing any resulting collection and use of your personal information are disclosed
              in the{' '}
              <b>
                <u>
                  <a
                    href="https://snappt.com/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </u>
              </b>
              . By using Service(s), you acknowledge and accept our{' '}
              <b>
                <u>
                  <a
                    href="https://snappt.com/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </u>
              </b>{' '}
              and consent to the collection and use of your data in accordance with the{' '}
              <b>
                <u>
                  <a
                    href="https://snappt.com/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </u>
              </b>
              . If you register for any feature that requires a password and/or username, then you
              will select your own password at the time of registration (or we may send you an
              e-mail notification with a randomly generated initial password) and you agree that:
              (i) You will not use a username (or e-mail address) that is already being used by
              someone else, may impersonate another person, belongs to another person, violates the
              intellectual property or other right of any person or entity, or is offensive. We may
              reject the use of any password, username, or e-mail address for any other reason in
              our sole discretion; (ii) You will provide true, accurate, current, and complete
              registration information about yourself in connection with the registration process
              and, as permitted, to maintain and update it continuously and promptly to keep it
              accurate, current, and complete; (iii) You are solely responsible for all activities
              that occur under your account, password, and username – whether or not you authorized
              the activity; (iv) You are solely responsible for maintaining the confidentiality of
              your password and for restricting access to your Device (defined below) so that others
              may not access any password protected portion of the platform using your name,
              username, or password; (v) You will immediately notify us of any unauthorized use of
              your account, password, or username, or any other breach of security; and (vi) You
              will not sell, transfer, or assign your account or any account rights. We will not be
              liable for any loss or damage (of any kind and under any legal theory) to you or any
              third party arising from your inability or failure for any reason to comply with any
              of the foregoing obligations. If any information that you provide, or if we have
              reasonable grounds to suspect that any information that you provide, is false,
              inaccurate, outdated, incomplete, or violates this Agreement, any Additional Terms, or
              any applicable law, then we may suspend or terminate your account. We also reserve the
              more general and broad right to terminate your account or suspend or otherwise deny
              you access to it or its benefits – all in our sole discretion, for any reason, and
              without advance notice or liability. You may not have more than one (1) active
              account.
            </Text>

            <Gap height={1} />
            <Text isStretched>
              Persons under thirteen (13) are prohibited from providing personal information on our
              Service(s). If you are under eighteen (18) or the age of majority in your
              state/jurisdiction of residence, you may only use our Service(s) with the supervision
              of a parent or guardian. IN PROVIDING THE SERVICE(S), YOU UNDERSTAND AND AGREE THAT
              COMPANY IS NOT A PARTY TO ANY AGREEMENTS ENTERED INTO BETWEEN LANDLORDS AND
              APPLICANTS, NOR IS COMPANY A REAL ESTATE BROKER, AGENT OR INSURER. COMPANY IS NOT AN
              OWNER OR OPERATOR OF PROPERTIES. COMPANY PROVIDES A PLATFORM TO CONNECT APPLICANTS AND
              LANDLORDS AND OTHER THIRD PARTIES AND HAS NO CONTROL OVER THE INFORMATION PROVIDED BY
              OR THE CONDUCT OF LANDLORDS, APPLICANTS AND THIRD PARTIES. YOU AGREE TO FULLY RELEASE
              AND HOLD COMPANY HARMLESS FROM ALL LIABILITY IN THIS REGARD.
            </Text>
            <Gap height={1} />
            <Text isStretched>
              <u>Document Submission and Similar Disputes</u>: If any controversy, allegation,
              dispute or claim arises out of or relates to the documentation submitted to or though
              the Service(s) (e.g., as an Applicant, you believe we mistakenly rejected your
              application), (collectively, “<b>Document Dispute</b>”), then you and we agree to
              engage in good-faith informal efforts to resolve the Document Dispute by you sending a
              written notice to us providing a reasonable description of the Document Dispute.
              Applicants shall submit information about their Document Dispute via email to{' '}
              <a href="mailto:snappt@documentportal.info">snappt@documentportal.info</a> and
              Landlords shall submit information about their Document Dispute to{' '}
              <a href="mailto:support@snappt.com">support@snappt.com</a>. The written description
              included in your email notice must be provide at least the following information: your
              name; the nature or basis of the dispute; the date of submission of your original
              documents in dispute, and any proposed resolution to your issue. For a period of sixty
              (60) days from the date of our receipt of your email notice, you and Snappt agree to
              negotiate in good faith about the dispute through an informal telephonic dispute
              resolution conference between you and Snappt in order to attempt to resolve the
              Document Dispute, though nothing will require either you or Snappt to resolve the
              Document Dispute on terms with respect to which you and Snappt, in each of our sole
              discretion, are not comfortable. To the extent that either an Applicant or Landlord
              that commences a Document Dispute and it goes unresolved within such sixty (60) day
              period referenced above, Applicant and Landlord’s exclusive recourse must be commenced
              via an arbitration proceeding pursuant to the terms set forth in Section 12 below.
            </Text>
          </li>
          <li>
            <Text isStretched>
              <u>Acceptable Use</u>.&nbsp;&nbsp;You are responsible for your use of the Service(s),
              and for any use of the Service(s) made using your account. Our goal is to create a
              positive and safe experience in connection with our Service(s). To promote this goal,
              we prohibit certain kinds of conduct that may be harmful to other users or to Company.
              When you use the Service(s), you may not:
            </Text>
            <Gap height={1} />
            <ul>
              <li>
                use the Service(s) to:
                <ul>
                  <li>
                    harvest, collect, gather, or assemble information or data regarding any job
                    candidate or other person without his or her consent;
                  </li>
                  <li>
                    engage in any other conduct that restricts or inhibits anyone’s use or enjoyment
                    of the Service(s), or which, as determined by us, may harm Snappt or users of
                    the Service(s) or expose any party to liability;
                  </li>
                  <li>
                    transmit information in a way that infringes upon the copyrights, trademarks,
                    trade secrets, or other intellectual property rights of any person or entity; or
                  </li>
                  <li>
                    reproduce, publish, or disseminate software, audio recordings, video recordings,
                    photographs, articles, or other works of authorship without the written
                    permission of the copyright holder.
                  </li>
                </ul>
              </li>
              <li>
                enter materials into the Service(s) that:
                <ul>
                  <li>
                    are deceptive, fraudulent, illegal, obscene, offensive, defamatory, slanderous,
                    libelous, threatening, harmful to minors, pornographic, indecent, harassing,
                    hateful, religiously, racially or ethnically offensive, that encourages illegal
                    or tortious conduct or that is otherwise inappropriate in Snappt’s discretion;
                  </li>
                  <li>
                    contain viruses, bots, worms, scripting exploits or other similar materials; or
                  </li>
                  <li>could otherwise cause damage to Snappt or any third party;</li>
                </ul>
              </li>
              <li>
                use the Service(s) in any way that violates any applicable laws, rules or
                regulations;
              </li>
              <li>
                access the Service(s) in order to build a competitive product or service, to build a
                product using similar ideas, features, functions or graphics of the Service(s), or
                to copy any ideas, features, functions or graphics of the Service(s);
              </li>
              <li>share non-public features or content of the Service(s) with any third party;</li>
              <li>
                use or transmit data that you don’t have the right to use or transmit (including
                confidential or personal information you are not authorized to disclose);
              </li>
              <li>
                • misrepresent yourself or disguise the origin of any data, content or other
                information you submit (including by “spoofing”, “phishing”, manipulating headers or
                other identifiers, impersonating anyone else, or falsely implying any sponsorship or
                association with Snappt or any third party);
              </li>
              <li>
                probe, scan, or test the vulnerability of any Service(s) or network used with the
                Service(s);
              </li>
              <li>
                tamper with, reverse engineer or hack the Service(s), circumvent any privacy or
                security or authentication measures of the Service(s) or knowingly interfere in any
                manner with the operation of the Service(s), the data contained therein, or the
                hardware and network used to operate the Service(s);
              </li>
              <li>
                attempt to gain unauthorized access to the Service(s) (or any portion thereof),
                related services, networks or data or the source code of any software making up the
                Service(s);
              </li>
              <li>
                encumber, distribute adapt, combine, create derivative works of or otherwise modify
                any portion of the Service(s);
              </li>
              <li>
                license, sublicense, sell, resell, rent, lease, transfer, assign, distribute, or
                otherwise commercially exploit or make the Service(s) available to any third party;
              </li>
              <li>
                access or search the Service(s) by any means other than our publicly supported
                interfaces, or copy, distribute, or disclose any part of the Service(s) in any
                medium, including, without limitation by any automated or non-automated “scraping”;
              </li>
              <li>
                overwhelm or attempt to overwhelm our infrastructure by imposing an unreasonably
                large load on the Service(s) that consume extraordinary resources, such as by: (i)
                using “robots,” “spiders,” “offline readers” or other automated means to send more
                request messages to our servers than a human could reasonably send in the same
                period of time using a normal browser; or (ii) going far beyond the use parameters
                contemplated for the Service(s);
              </li>
              <li>access the Service(s) via another user’ account without their permission;</li>
              <li>
                remove or obscure any proprietary or other notices contained in the Service(s);
              </li>
              <li>
                use meta tags or any other “hidden text” including Snappt’s or our suppliers’
                product names or trademarks;
              </li>
              <li>
                use or attempt use the Service(s) in violation of this Agreement or any MSA or other
                agreement to which you are a party; or
              </li>
              <li>permit or encourage anyone else to commit any of the actions above.</li>
            </ul>
          </li>
          <li>
            <Text>
              <u>Ownership & Copyright Restrictions</u>.&nbsp;&nbsp;
            </Text>
            <Gap height={1} />
            <ol type="A">
              <li>
                Content. The Service(s) contain a variety of: (i) materials and other items relating
                to Snappt, Snappt’s Website(s) and platform, and similar items from our licensors
                and other third parties, including all software, layout, information, text, data,
                files, images, scripts, designs, graphics, button icons, instructions,
                illustrations, photographs, audio clips, music, sounds, pictures, videos,
                advertising copy, URLs, technology, software, interactive features, the “look and
                feel” of the Platform, and the compilation, assembly, and arrangement of the
                materials of the Platform and any and all copyrightable material (including source
                and object code); (ii) trademarks, trade dress, logos, trade names, service marks,
                and/or trade identities of various parties, including those of Snappt (collectively,
                “<b>Trademarks</b>”); and (iii) other forms of intellectual property (all of the
                foregoing, collectively “<b>Content</b>”).
              </li>
              <li>
                Ownership. The Service(s) (including past, present and future versions) and the
                Content are owned or controlled by Snappt and our licensors and certain other third
                parties. All right, title, and interest in and to the Content available via the
                Service(s) is the property of Snappt or our licensors or certain other third
                parties, and is protected by U.S. and international copyright, trademark, trade
                dress, patent, or other intellectual property and unfair competition rights and laws
                to the fullest extent possible. Snappt owns the copyright in the selection,
                compilation, assembly, arrangement, and enhancement of the Content on the
                Service(s).
              </li>
              <li>
                Limited License. Snappt will make the Service(s) available to you on a non-exclusive
                basis for your use in accordance with the terms of this Agreement. Subject to your
                strict compliance with this Agreement and the Additional Terms and your payment of
                any fees, Snappt grants you a limited, non-exclusive, revocable, non-assignable,
                personal, and non-transferable license to download (temporary storage only),
                display, view, use, play, and/or print one copy of the platform (excluding source
                and object code in raw form or otherwise, other than as made available to access and
                use to enable display and functionality) on a Device for your internal business use
                only. The foregoing limited license: (i) does not give you any ownership of, or any
                other intellectual property interest in, any of the platform, Service(s) or Content,
                and (ii) may be immediately suspended or terminated for any reason, in Snappt’s sole
                discretion, and without advance notice or liability. In some instances, we may
                permit you to have greater access to and use of platform, Service(s) and Content,
                subject to certain Additional Terms.
              </li>
              <li>
                Availability of Service(s) and Content. Company may immediately suspend or terminate
                the availability of the Service(s) and Content (and any elements and features of
                them), in whole or in part, for any reason, in Company’s sole discretion, and
                without advance notice or liability.
              </li>
              <li>
                Reservation of All Rights Not Granted as to Service(s) and Content. This Agreement
                and any Additional Terms include only narrow, limited grants of rights to Content
                and to use and access the Service(s). No right or license may be construed, under
                any legal theory, by implication, estoppel, industry custom, or otherwise. All
                rights not expressly granted to you are reserved by Company and its licensors and
                other third parties.{' '}
                <b>
                  Any unauthorized use of any Content or the Service(s) for any purpose is
                  prohibited
                </b>
                . Except as expressly provided in a signed written agreement between you and Snappt:
                (1) the Service(s) may not be used, displayed, copied, reproduced, distributed,
                republished, uploaded, downloaded, posted, transmitted, mirrored, modified, or
                otherwise, or in any way exploited for personal gain; (2) you may not redistribute,
                sell, translate, modify, reverse-engineer or reverse-compile or decompile,
                disassemble or make derivative works of the Service(s) or any Content or components
                that are available on the Service(s); and (3) you may not make commercial use of any
                webpage or Service(s) provided by Company, including redistribution or copying by
                means. You agree not to interfere or take action that results in interference with
                or disruption of the Service(s) or servers or networks connected to the Service(s).
                You agree not to attempt to gain unauthorized access to other computer systems or
                networks connected to the Service(s). Company reserves all other rights. Except as
                expressly provided herein, nothing on the Service(s) will be construed as conferring
                any license under Company’s and/or any third party’s intellectual property rights,
                whether by estoppel, implication or otherwise. Notwithstanding anything herein to
                the contrary, Company may revoke any of the foregoing rights and/or your access to
                the Service(s), including the Website(s), or any part thereof, at any time without
                prior notice. You may not delete any legal or proprietary notices in the Service(s)
                or elsewhere.
              </li>
            </ol>
            <Gap height={1} />
          </li>
          <li>
            <Text isStretched>
              <u> Electronic Disclosure Agreement</u>.&nbsp;&nbsp;You agree that you have read and
              consent to be bound by the following Electronic Disclosure terms of this Section 5.
            </Text>
            <Gap height={1} />

            <Text isStretched>
              All documents, including, but not limited to, the Electronic Disclosure terms of this
              Section 5, the Agreement as a whole, the Company{' '}
              <b>
                <u>
                  <a
                    href="https://snappt.com/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </u>
              </b>
              , and other documents which may apply, as well as all notices, disclosures and records
              (collectively, “<b>Disclosures</b>”) relating to your account with Company may be sent
              to you electronically at the email address indicated by you in the account
              registration form or by viewing the Disclosures on the Internet. Alternatively,
              Company may provide you with Disclosures via fax or in the mail. You hereby consent
              and agree to receive all Disclosures from Company electronically by email or by
              viewing the Disclosures on the Internet.
            </Text>

            <Gap height={1} />
            <Text isStretched>
              To access Disclosures electronically and print copies of these Disclosures for your
              records, you must have Internet access, as well as access to a printer. You confirm
              that you have access to a computer that is capable of accessing the Internet and
              operating an Internet browser that enables you to access the Service(s). You also
              confirm that you have access to a printer or the ability to make hard copies of your
              information and records. Please print a copy of all Disclosures for your records.
            </Text>

            <Gap height={1} />
            <Text isStretched>
              You agree to promptly notify us of any changes in your email address or your home
              address by updating your profile online from within the Company website at{' '}
              <a href="https://www.snappt.com/" target="_blank" rel="noopener noreferrer">
                https://www.snappt.com
              </a>{' '}
              or by emailing us at{' '}
              <a href="mailto:snappt@documentportal.info"> snappt@documentportal.info</a>. If you
              have questions about receiving Disclosures, or need technical or other assistance
              concerning these Disclosures, you may contact us by email at the same email address.
            </Text>
            <Gap height={1} />
            <Text isStretched>
              If you would like a paper copy of the documents or these Electronic Disclosure terms
              sent to you by mail or fax, please send your request in writing to 8735 Dorrington
              Ave, Los Angeles, CA 90048, ATTN: Snappt Legal.
            </Text>
            <Gap height={1} />
          </li>

          <li>
            <Text isStretched>
              <u> Feedback and Material Submissions</u>.
            </Text>
            <Gap height={1} />
            <ol type="A">
              <li>
                General. Snappt may now or in the future offer users of the Service(s) the
                opportunity to post, upload, display, publish, distribute, transmit or otherwise
                make available on or submit through the Service(s), messages, text, files, comments,
                responses, information, content, results, reviews, suggestions, personally
                identifiable information, or other information or materials and the ideas contained
                therein (collectively, “<b>Feedback</b>”). Snappt may allow you to do this through
                contact us, email, and other communications functionality. Subject to the rights and
                license you grant in this Agreement, you retain whatever legally cognizable right,
                title, and interest that you have in your Feedback and you remain ultimately
                responsible for it.
              </li>
              <li>
                Non-Confidentiality of Your Feedback. You agree that: (a) your Feedback will be
                treated as non-confidential – regardless of whether you mark them “confidential,”
                “proprietary,” or the like – and will not be returned; and (b) Snappt does not
                assume any obligation of any kind to you or any third party with respect to your
                Feedback. Upon Snappt’s request, you will furnish us with any documentation
                necessary to substantiate the rights to such content and to verify your compliance
                with this Agreement or any Additional Terms.
              </li>
              <li>
                License to Snappt of Your Feedback. Except as otherwise described in any applicable
                Additional Terms, which specifically govern the submission of your Feedback), you
                hereby grant to Snappt, and you agree to grant to Snappt, the non-exclusive,
                unrestricted, unconditional, unlimited, worldwide, irrevocable, perpetual, and
                cost-free right and license to use, copy, record, distribute, reproduce, disclose,
                sell, re-sell, sublicense (through multiple levels), display, publicly perform,
                transmit, publish, broadcast, translate, make derivative works of, and otherwise use
                and exploit in any manner whatsoever, all or any portion of your Feedback (and
                derivative works thereof), for any purpose whatsoever in all formats, on or through
                any means or medium now known or hereafter developed, and with any technology or
                devices now known or hereafter developed, and to advertise, market, and promote the
                same. Without limitation, the granted rights include the right to: (a) configure,
                host, index, cache, archive, store, digitize, compress, optimize, modify, reformat,
                edit, adapt, publish in searchable format, and remove such Feedback and combine same
                with other materials, and (b) use any ideas, concepts, know-how, or techniques
                contained in any Feedback for any purposes whatsoever, including developing,
                producing, and marketing products and/or services. In order to further effect the
                rights and license that you grant to Snappt to your Feedback, you also hereby grant
                to Snappt, and agree to grant to Snappt, the unconditional, perpetual, irrevocable
                right to use and exploit your name, persona, and likeness in connection with any
                Feedback, without any obligation or remuneration to you. Except as prohibited by
                law, you hereby waive, and you agree to waive, any moral rights (including
                attribution and integrity) that you may have in any Feedback, even if it is altered
                or changed in a manner not agreeable to you. To the extent not waivable, you
                irrevocably agree not to exercise such rights (if any) in a manner that interferes
                with any exercise of the granted rights. You understand that you will not receive
                any fees, sums, consideration, or remuneration for any of the rights granted in this
                Section 6(C).
              </li>
              <li>
                <u>Representations and Warranties Related to Feedback</u>. You represent and warrant
                that: (i) you either are the sole and exclusive owner of all of your Feedback that
                you make available through the Service(s) or you have all rights, licenses, consents
                and releases that are necessary to grant to Company the rights in your Feedback, as
                contemplated under this Agreement; and (ii) neither your Feedback, nor your posting,
                uploading, publication, submission or transmittal of your Feedback or Company's use
                of your Feedback (or any portion thereof) on, through or by means of the Service(s)
                will infringe, misappropriate or violate a third party’s patent, copyright,
                trademark, trade secret, moral rights or other proprietary or intellectual property
                rights, or rights of publicity or privacy, or result in the violation of any
                applicable law or regulation.
              </li>
            </ol>
            <Gap height={1} />
          </li>
          <li>
            <Text isStretched>
              <u> Privacy Policy</u>.&nbsp;&nbsp; Company has customer information and privacy
              policies as set forth in its online Privacy Policy found at{' '}
              <a
                href="https://www.snappt.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.snappt.com/privacy-policy
              </a>
              , which is fully incorporated herein by reference. You hereby agree to be bound by
              Company's Privacy Policy and consent to having your information processed in
              accordance with the Privacy Policy. Consistent with these policies, you may not
              collect, distribute or gather personal or aggregate information, data or materials,
              including Internet or e-mail addresses, about Company's customers or other users in
              connection with your use of the Service(s).
            </Text>
            <Gap height={1} />
          </li>
          <li>
            <Text isStretched>
              <u> DISCLAIMER</u>.&nbsp;&nbsp;
            </Text>
            <Gap height={1} />
            <Text isStretched>
              YOUR ACCESS TO AND USE OF THE SERVICE(S) IS AT YOUR SOLE RISK. THE SERVICE(S) ARE
              PROVIDED ON AN “AS IS”, “AS AVAILABLE”, AND “WITH ALL FAULTS” BASIS. Therefore, to the
              fullest extent permissible by law, Snappt, its parent company and subsidiaries, and
              each of their respective employees, officers, directors, members, managers,
              shareholders, agents, vendors, licensors, licensees, contractors, customers,
              successors, and assigns (collectively, the “<b>Snappt Parties</b>”), hereby disclaim
              and make no representations, warranties, endorsements, or promises, express or
              implied, as to:
            </Text>
            <Gap height={1} />
            <ol type="a">
              <li>the Service(s) (including the Content and Feedback);</li>
              <li>
                the functions, features, or any other elements on, or made accessible through, the
                Service(s);
              </li>
              <li>
                any products, services, developer code or instructions offered or referenced at or
                linked through the Service;
              </li>
              <li>security associated with the transmission of your Feedback;</li>
              <li>
                whether the Service(s) or the servers that make the Service(s) available are free
                from any harmful components (including viruses, Trojan horses, and other
                technologies that could adversely impact your Device);
              </li>
              <li>
                whether the information (including any instructions) on the Service(s) is accurate,
                complete, correct, adequate, useful, timely, or reliable;
              </li>
              <li>
                whether any defects to, or errors on, the Service(s) will be repaired or corrected;
              </li>
              <li>whether your access to the Service(s) will be uninterrupted;</li>
              <li>
                whether the Service(s) will be available at any particular time or location; and
              </li>
              <li>whether your use of the Service(s) is lawful in any particular jurisdiction.</li>
            </ol>
            <Gap height={1} />
            <Text isStretched>
              EXCEPT FOR ANY SPECIFIC WARRANTIES PROVIDED HEREIN OR IN ADDITIONAL TERMS PROVIDED BY
              A SNAPPT PARTY, TO THE EXTENT PERMITTED BY APPLICABLE LAW, THE SNAPPT PARTIES HEREBY
              FURTHER DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING THE WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OR
              MISAPPROPRIATION OF INTELLECTUAL PROPERTY RIGHTS OF THIRD PARTIES, TITLE, CUSTOM,
              TRADE, QUIET ENJOYMENT, SYSTEM INTEGRATION, AND FREEDOM FROM COMPUTER VIRUS.
            </Text>
            <Gap height={1} />
            <Text isStretched>
              Some jurisdictions limit or do not allow the disclaimer of implied or other warranties
              so the above disclaimers may not apply to the extent such jurisdictions’ laws are
              applicable.
            </Text>
            <Gap height={1} />
          </li>
          <li>
            <Text isStretched>
              <u>LIMITED LIABILITY</u>.&nbsp;&nbsp; TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
              LAWS, YOU AGREE THAT THE SNAPPT PARTIES WILL NOT BE LIABLE FOR ANY PERSONAL INJURY OR
              FOR ANY INDIRECT, CONSEQUENTIAL OR SPECIAL DAMAGES, INCLUDING LOST PROFITS OR
              OTHERWISE, ARISING OUT OF OR RELATING IN ANY WAY TO THIS AGREEMENT, OR YOUR USE OF THE
              SERVICE(S).
            </Text>
            <Gap height={1} />

            <Text isStretched>
              THE CUMULATIVE LIABILITY OF THE SNAPPT PARTIES TO YOU FOR ALL CLAIMS ARISING FROM OR
              RELATING TO THIS AGREEMENT, YOUR USE OF THE SERVICE(S), INCLUDING, WITHOUT LIMITATION,
              ANY CAUSE OF ACTION SOUNDING IN CONTRACT, TORT, OR STRICT LIABILITY, WILL NOT EXCEED
              THE GREATER OF: (A) THE TOTAL AMOUNT PAID TO COMPANY BY YOU DURING THE SIX (6)-MONTH
              PERIOD PRIOR TO THE ACT, OMISSION OR OCCURRENCE GIVING RISE TO SUCH LIABILITY, OR (B)
              $100 (USD). THIS LIMITATION OF LIABILITY IS INTENDED TO APPLY WITHOUT REGARD TO
              WHETHER OTHER PROVISIONS OF THIS AGREEMENT HAVE BEEN BREACHED OR HAVE PROVEN
              INEFFECTIVE OR IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE. SOME JURISDICTIONS DO NOT
              ALLOW FOR LIMITED LIABILITY OR EXCLUSION OF IMPLIED WARRANTIES, SO NOT ALL OF THE
              ABOVE LIMITATIONS MAY APPLY TO YOU. YOU ACKNOWLEDGE AND UNDERSTAND THAT THE
              DISCLAIMERS, EXCLUSIONS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN FORM AN
              ESSENTIAL BASIS OF THE AGREEMENT BETWEEN THE PARTIES HERETO, THAT THE PARTIES HAVE
              RELIED UPON SUCH DISCLAIMERS, EXCLUSIONS AND LIMITATIONS OF LIABILITY, AND THAT ABSENT
              SUCH DISCLAIMERS, EXCLUSIONS AND LIMITATIONS OF LIABILITY, THE TERMS AND CONDITIONS OF
              THIS AGREEMENT WOULD BE SUBSTANTIALLY DIFFERENT.
            </Text>
            <Gap height={1} />
          </li>
          <li>
            <Text isStretched>
              <u>INDEMNIFICATION</u>.&nbsp;&nbsp; You agree to, and you hereby, defend, indemnify,
              and hold the Snappt Parties harmless from and against any and all claims, damages,
              losses, costs, investigations, liabilities, judgments, fines, penalties, settlements,
              interest, and expenses (including attorneys’ fees) that directly or indirectly arise
              from or are related to any claim, suit, action, demand, or proceeding made or brought
              against any Snappt Party, or on account of the investigation, defense, or settlement
              thereof, arising out of or in connection with, whether occurring heretofore or
              hereafter: (i) Feedback; (ii) your use of the platform and our Service(s) and your
              activities in connection with the platform and Service(s), including, without
              limitation, any transaction entered into by you through your use of the platform and
              Service(s); (iii) your breach or alleged breach of this Agreement or any Additional
              Terms; (iv) your violation or alleged violation of any laws, rules, regulations,
              codes, statutes, ordinances, or orders of any governmental or quasi-governmental
              authorities in connection with your use of the platform, Service(s) or your activities
              in connection therewith; (v) information or material transmitted through your Device,
              even if not submitted by you, that infringes, violates, or misappropriates any
              copyright, trademark, trade secret, trade dress, patent, publicity, privacy, or other
              right of any person or entity; (vi) any misrepresentation made by you; (vii) any other
              party’s access and/or use of the platform/Service(s) with your account; (viii) any
              failure to obtain consents required by applicable law or regulations for Snappt to
              send communications on your behalf; and (ix) Snappt Parties’ use of the information
              that you submit to us (including your Feedback) (all of the foregoing, “
              <b>Claims and Losses</b>”). You will cooperate as fully required by Snappt Parties in
              the defense of any Claim and Losses. Notwithstanding the foregoing, the Snappt Parties
              retain the exclusive right to settle, compromise, and pay any and all Claims and
              Losses. The Snappt Parties reserve the right to assume the exclusive defense and
              control of any Claims and Losses. You will not settle any Claims and Losses without,
              in each instance, the prior written consent of an officer of a Snappt Party.
            </Text>
            <Gap height={1} />
          </li>
          <li>
            <Text isStretched>
              <u>Governing Law</u>.&nbsp;&nbsp; This Agreement will be governed by and interpreted
              in accordance with the laws of the State of California, without regard to any conflict
              of laws principles.
            </Text>
            <Gap height={1} />
          </li>
          <li>
            <Text isStretched>
              <u> Dispute Resolution/ Arbitration Agreement.</u>&nbsp;&nbsp;
            </Text>
            <Gap height={1} />
            <Text isStretched>
              Certain portions of this Section 12 are deemed to be a “written agreement to
              arbitrate” pursuant to the Federal Arbitration Act. You and Snappt agree that we
              intend that this Section 12 satisfies the “writing” requirement of the Federal
              Arbitration Act.
            </Text>
            <Gap height={1} />
            <ol type="A">
              <li>
                First – Try to Resolve Disputes and Excluded Disputes. If any controversy,
                allegation, or claim arises out of or relates to the platform, the Service(s), the
                Content, your Feedback, this Agreement, or any Additional Terms, whether heretofore
                or hereafter arising (collectively, “<b>Dispute</b>”), or to any of Snappt’ actual
                or alleged intellectual property rights (an “<b>Excluded Dispute</b>”, which
                includes those actions set forth in Section 12(D), then you and we agree to send a
                written notice to the other providing a reasonable description of the Dispute or
                Excluded Dispute, along with a proposed resolution of it. Our notice to you will be
                sent to you based on the most recent contact information that you provide us. But if
                no such contact information exists or if such information is not current, then we
                have no obligation under this Section 12(A). Your notice to us must be sent to:
                Snappt Inc., 8735 Dorrington Ave, Los Angeles, CA 90048 (Attn: Snappt Legal). For a
                period of sixty (60) days from the date of receipt of notice from the other party,
                Snappt and you will engage in a dialogue in order to attempt to resolve the Dispute
                or Excluded Dispute, though nothing will require either you or Snappt to resolve the
                Dispute or Excluded Dispute on terms with respect to which you and Snappt, in each
                of our sole discretion, are not comfortable.
              </li>
              <li>
                Binding Arbitration. If we cannot resolve a Dispute as set forth in Section 12(A)
                (or agree to arbitration in writing with respect to an Excluded Dispute) within
                sixty (60) days of receipt of the notice, then ANY AND ALL DISPUTES ARISING BETWEEN
                YOU AND SNAPPT MUST BE RESOLVED BY FINAL AND BINDING ARBITRATION. THIS INCLUDES ANY
                AND ALL DISPUTES BASED ON ANY PRODUCT, SERVICES OR ADVERTISING CONNECTED TO THE
                PROVISION OR USE OF THE PLATFORM (WHETHER BASED IN CONTRACT, STATUTE, REGULATION,
                ORDINANCE, TORT - INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, FRAUD, ANY OTHER
                INTENTIONAL TORT OR COMMON LAW, CONSTITUTIONAL PROVISION, RESPONDEAT SUPERIOR,
                AGENCY OR ANY OTHER LEGAL OR EQUITABLE THEORY - AND WHETHER ARISING BEFORE OR AFTER
                THE EFFECTIVE DATE OF THIS AGREEMENT. The Federal Arbitration Act (“<b>FAA</b>”)
                shall govern the arbitrability of all disputes between Snappt and you regarding this
                Agreement (and any Additional Terms) and the Platform and Services, including the No
                Class Action Matters section below. BY AGREEING TO ARBITRATE, EACH PARTY IS GIVING
                UP ITS RIGHT TO GO TO COURT AND HAVE ANY DISPUTE HEARD BY A JUDGE OR JURY. <br />{' '}
                <br />A Dispute will be resolved solely by binding arbitration administered by the
                Judicial Arbitration and Mediation Services Inc. (“<b>JAMS</b>”), in accordance with
                the then-current streamlined Arbitration Rules and Procedures (“<b>Rules</b>”) of,
                or by any other arbitration administration service that you and an officer or legal
                representative of Snappt consent to in writing. If an in-person arbitration hearing
                is required, then it will be conducted in the “metropolitan statistical area” (as
                defined by the U.S. Census Bureau) where you are a resident at the time the Dispute
                is submitted to arbitration. You and we will pay the administrative and arbitrator’s
                fees and other costs in accordance with the applicable Rules; but if applicable
                Rules or laws require Snappt to pay a greater portion or all of such fees and costs
                in order for this Section 12 to be enforceable, then Snappt will have the right to
                elect to pay the fees and costs and proceed to arbitration. The arbitrator will
                apply and be bound by this Agreement and any Additional Terms, and will determine
                any Dispute according to applicable law and facts based upon the record and no other
                basis, and will issue a reasoned award only in favor of the individual party seeking
                relief and only to the extent to provide relief warranted by that party’s individual
                claim. Issues relating to the enforceability of the arbitration and class action
                waiver provisions contained herein are for the court to decide.
              </li>
              <li>
                Limited Time to File Claims. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IF
                YOU OR WE WANT TO ASSERT A DISPUTE (BUT NOT AN EXCLUDED DISPUTE) AGAINST THE OTHER,
                THEN YOU OR WE MUST COMMENCE IT (BY DELIVERY OF WRITTEN NOTICE AS SET FORTH IN
                SECTION 12(A)) WITHIN ONE (1) YEAR AFTER THE DISPUTE ARISES -- OR IT WILL BE FOREVER
                BARRED. Commencing means, as applicable: (a) by delivery of written notice as set
                forth above in Section 12(A); (b) filing for arbitration with JAMS as set forth in
                Section 12(B); or (c) filing an action in state or Federal court. The parties
                expressly waive any contrary statute of limitations or time bars, both legal and
                equitable, to the Disputes.
              </li>
              <li>
                Injunctive Relief. The foregoing provisions of this Section 12 will not apply to any
                legal action taken by Snappt to seek an injunction or other equitable relief in
                connection with, any loss, cost, or damage (or any potential loss, cost, or damage)
                relating to the platform, the Service(s), any Content, your Feedback and/or Snappt’s
                intellectual property rights (including such Snappt may claim that may be in
                dispute), Snappt’s operations, and/or Snappt’s products or services.
              </li>
              <li>
                No Class Action Matters. YOU AND SNAPPT AGREE THAT EACH MAY BRING CLAIMS AGAINST THE
                OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS
                MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING OR AS AN ASSOCIATION.
                Disputes will be arbitrated only on an individual basis and will not be joined or
                consolidated with any other arbitrations or other proceedings that involve any claim
                or controversy of any other party. There shall be no right or authority for any
                Dispute to be arbitrated on a class action basis or on any basis involving Disputes
                brought in a purported representative capacity on behalf of the general public, or
                other persons or entities similarly situated. But if, for any reason, any court with
                competent jurisdiction holds that this restriction is unconscionable or
                unenforceable, then our agreement in Section 12(B) to arbitrate will not apply and
                the Dispute must be brought exclusively in court pursuant to Section 12(F).
                Notwithstanding any other provision of this Section 12, any and all issues relating
                to the scope, interpretation and enforceability of the class action waiver
                provisions contained herein (described in this “No Class Action Matters” section),
                are to be decided only by a court of competent jurisdiction, and not by the
                arbitrator. The arbitrator does not have the power to vary these class action waiver
                provisions.
              </li>
              <li>
                Federal and State Courts in Los Angeles County, California. Except where arbitration
                is required above, small claims actions, or with respect to the enforcement of any
                arbitration decision or award, any action or proceeding relating to any Dispute or
                Excluded Dispute arising hereunder may only be instituted in state or Federal court
                in Los Angeles County, California. Accordingly, you and Snappt consent to the
                exclusive personal jurisdiction and venue of such courts for such matters.
              </li>
              <li>
                Small Claims Matters Are Excluded from Arbitration Requirement. Notwithstanding the
                foregoing, either of us may bring qualifying claim of Disputes (but not Excluded
                Disputes) in small claims court of competent jurisdiction.
              </li>
            </ol>
            <Gap height={1} />
          </li>
          <li>
            <Text isStretched>
              <u>Termination.</u>
              &nbsp;&nbsp;We may, in our discretion and without liability to you, with or without
              cause, with or without prior notice and at any time: (a) terminate this Agreement or
              your access to the Service(s), and (b) deactivate or cancel your Company account. In
              the event Company terminates this Agreement or your access to the Service(s) or
              deactivates or cancels your Company account, you will remain liable for all amounts
              due hereunder. Please note that if your Company account is cancelled, we do not have
              any obligation to delete or return to you any of your Feedback that you have posted to
              the Service(s).
            </Text>
            <Gap height={1} />
          </li>
          <li>
            <Text isStretched>
              <u>Waiver</u>. &nbsp;&nbsp; No delay or omission by either party hereto to exercise
              any right or power occurring upon any noncompliance or default by the other party with
              respect to any of the terms of this Agreement shall impair any such right or power or
              be construed to be a waiver thereof. The terms and conditions of this Agreement may be
              waived or amended only in writing and only by the party that is entitled to the
              benefits of the term(s) or condition(s) being waived or amended. Unless stated
              otherwise, all remedies provided for in this Agreement shall be cumulative and in
              addition to and not in lieu of any other remedies available to either party at law, in
              equity, or otherwise.
            </Text>
            <Gap height={1} />
          </li>
          <li>
            <Text isStretched>
              <u>Waiver of Injunctive or Other Equitable Relief</u>. &nbsp;&nbsp;IF YOU CLAIM THAT
              YOU HAVE INCURRED ANY LOSS, DAMAGES, OR INJURIES IN CONNECTION WITH YOUR USE OF THE
              PLATFORM AND OUR PROVISION OF THE SERVICES, THEN THE LOSSES, DAMAGES, AND INJURIES
              WILL NOT BE IRREPARABLE OR SUFFICIENT TO ENTITLE YOU TO AN INJUNCTION OR TO OTHER
              EQUITABLE RELIEF OF ANY KIND. THIS MEANS THAT, IN CONNECTION WITH YOUR CLAIM, YOU
              AGREE THAT YOU WILL NOT SEEK, AND THAT YOU WILL NOT BE PERMITTED TO OBTAIN, ANY COURT
              OR OTHER ACTION THAT MAY INTERFERE WITH OR PREVENT THE DEVELOPMENT OR EXPLOITATION OF
              ANY WEBSITE, APPLICATION, CONTENT, PRODUCT, SERVICE, OR INTELLECTUAL PROPERTY OWNED,
              LICENSED, USED OR CONTROLLED BY SNAPPT OR A LICENSOR OF SNAPPT.
            </Text>
            <Gap height={1} />
          </li>
          <li>
            <Text isStretched>
              <u>Unenforceability</u>.&nbsp;&nbsp; If any provision of this Agreement or any word,
              phrase, clause, sentence, or other portion thereof should be held to be unenforceable
              or invalid for any reason, then such provision or portion thereof shall be modified or
              deleted in such manner as to render this Agreement as modified legal and enforceable
              to the maximum extent permitted under applicable laws.
            </Text>
            <Gap height={1} />
          </li>
          <li>
            <Text isStretched>
              <u>Authority</u>.&nbsp;&nbsp; The party entering into this Agreement hereby
              acknowledges, represents and warrants that he or she is expressly and duly authorized
              to enter into this Agreement and to legally bind said party to this Agreement.
            </Text>
            <Gap height={1} />
          </li>
          <li>
            <Text isStretched>
              <u>Assignment</u>.&nbsp;&nbsp; This Agreement, and any rights and licenses granted
              hereunder, may not be transferred or assigned by you, but may be assigned by Company
              without restriction.
            </Text>
            <Gap height={1} />
          </li>
          <li>
            <Text isStretched>
              <u>Notices</u>.&nbsp;&nbsp; You may contact us by writing us at the address or email
              address listed below:
              <br />
              <br />
              Snappt Inc.
              <br />
              8735 Dorrington Ave
              <br />
              Los Angeles, CA 90048
            </Text>
            <Gap height={1} />
          </li>
          <li>
            <Text isStretched>
              <u>Investigations; Cooperation with Law Enforcement; Termination; Survival</u>
              .&nbsp;&nbsp; Snappt reserves the right, without any limitation, to: (i) investigate
              any suspected breaches of its Platform security or its information technology or other
              systems or networks, (ii) investigate any suspected breaches of this Agreement and any
              Additional Terms, (iii) investigate any information obtained by Snappt in connection
              with reviewing law enforcement databases or complying with criminal laws, (iv) involve
              and cooperate with law enforcement authorities in investigating any of the foregoing
              matters, (v) prosecute violators of this Agreement and any Additional Terms, and (vi)
              discontinue the platform and/or Service(s), in whole or in part, or, except as may be
              expressly set forth in any Additional Terms, suspend or terminate your access to it,
              in whole or in part, including any user accounts or registrations, at any time,
              without notice, for any reason and without any obligation to you or any third party.
              Any suspension or termination will not affect your obligations to Snappt under this
              Agreement or any Additional Terms. Upon suspension or termination of your access to
              the platform/Service(s), or upon notice from Snappt, all rights granted to you under
              this Agreement or any Additional Terms will cease immediately, and you agree that you
              will immediately discontinue use of the platform and Service(s). The provisions of
              this Agreement and any Additional Terms, which by their nature should survive your
              suspension or termination will survive, including the rights and licenses you grant to
              Snappt in this Agreement, as well as the indemnities, releases, disclaimers, and
              limitations on liability and the provisions regarding jurisdiction, choice of law, no
              class action, and mandatory arbitration.
            </Text>
            <Gap height={1} />
          </li>
          <li>
            <Text isStretched>
              <u>Connectivity</u>.&nbsp;&nbsp; You are responsible for obtaining and maintaining all
              Devices and other equipment and software, and all internet service provider, mobile
              service, and other services needed for your access to and use of the platform and
              Service(s) and you will be responsible for all charges related to them.
            </Text>
            <Gap height={1} />
          </li>
          <li>
            <Text isStretched>
              <u>Export Controls</u>.&nbsp;&nbsp; Software related to or made available by the
              Service(s) may be subject to export controls of the U.S.A. No software from the
              Service(s) may be downloaded, exported, or re-exported: (i) into (or to a national or
              resident of) any country or other jurisdiction to which the U.S.A. has embargoed
              goods, software, technology or services (which, as of the effective date of this
              Agreement, includes Cuba, North Korea, Iran, Sudan, and Syria), or (ii) to anyone on
              the U.S. Treasury Department’s list of Specially Designated Nationals or the U.S.
              Commerce Department’s Table of Deny Orders, or (iii) to anyone on the U.S. Department
              of Commerce’s Bureau of Industry and Security Entities List as published in the Export
              Administration Regulations (including entities engaged in weapons of mass destruction
              proliferation in various countries and persons and entities that are suspected of
              diverting U.S. origin items to embargoed countries or terrorist end-uses). You are
              responsible for complying with all trade regulations and laws both foreign and
              domestic. Except as authorized by law, you agree and warrant not to export or
              re-export the software to any county, or to any person, entity, or end-user subject to
              U.S. export controls, including as set forth in subsections (i) – (iii) above.
            </Text>
            <Gap height={1} />
          </li>
        </ol>
        <Text isStretched>
          PLEASE PRINT A COPY OF THIS AGREEMENT FOR YOUR RECORDS AND PLEASE CHECK BACK FREQUENTLY
          FOR ANY CHANGES TO THIS AGREEMENT.
        </Text>
      </Card>
    </Section>
  );
};
