import styled from 'styled-components';

export const Container = styled.div`
  font-family: 'Montserrat';
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: white;

  padding: 0.5rem 8rem;
  @media ${({ theme }) => theme.device.mobile} {
    padding: 0.5rem;
  }
`;
