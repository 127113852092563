import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { ButtonVariant } from 'src/components/atoms/Button';
import { Button, FormContainer } from 'src/components/DUP/molecules/WizardFormApplicant/styles';
import useLanguage from 'src/context/Language/useLanguage';
import { Information } from 'src/features/DUP/helpers/getInformation';
import { useFormApplicant } from 'src/hooks/useFormApplicant';
import { DupScreenStepProps } from 'src/pages/DUPPage/DUPWizard';
import { DupApplicationType } from 'src/types/api';

const WizardFormApplicant = (props: DupScreenStepProps & { information: Information }) => {
  const { translate: t } = useLanguage();
  const [isFormValid, setIsFormValid] = useState(false);
  const {
    form,
    UnitInput,
    FirstNameInput,
    MiddleInitialInput,
    LastNameInput,
    EmailInput,
    PhoneInput,
    HavePrevSubmitRow
  } = useFormApplicant({
    ...props,
    isWizard: true,
    setIsFormValid,
    type: DupApplicationType.UNAUTHENTICATED_USER
  });

  return (
    <FormContainer>
      <FormProvider {...form}>
        {UnitInput}
        {FirstNameInput}
        {MiddleInitialInput}
        {LastNameInput}
        {EmailInput}
        {PhoneInput}
        {HavePrevSubmitRow}
      </FormProvider>
      <Button
        name="submit"
        isDisabled={!isFormValid}
        onClick={() => props.onFinished()}
        variant={ButtonVariant.contained}
      >
        {t('dup_button_label_next')}
      </Button>
    </FormContainer>
  );
};

export default WizardFormApplicant;
