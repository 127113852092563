import PropTypes from 'prop-types';
import { HTMLAttributes } from 'react';
import { Body, Card as StyledCard } from './styles';

type AlignContent = 'center' | 'left' | 'right';

export interface CardProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  border?: boolean;
  background?: 'white' | 'blue';
  elevation?: number;
  rounded?: boolean;
  alignContent?: AlignContent;
}

const Card: React.FC<CardProps> = (props) => {
  const {
    children,
    border,
    background = 'white',
    elevation,
    rounded,
    alignContent = 'left',
    ...htmlAttrs
  } = props;
  return (
    <StyledCard
      elevation={elevation}
      border={border}
      background={background}
      rounded={rounded}
      alignContent={alignContent}
      {...htmlAttrs}
    >
      <Body className="CBody">{children}</Body>
    </StyledCard>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  border: PropTypes.bool,
  background: PropTypes.oneOf(['white', 'blue']),
  elevation: PropTypes.number,
  rounded: PropTypes.bool,
  alignContent: PropTypes.oneOf<AlignContent>(['center', 'left', 'right'])
};

Card.defaultProps = {
  border: false,
  background: 'white',
  rounded: false
};

export default Card;
