import Button, { ButtonSize, ButtonVariant } from 'src/components/atoms/Button';
import Text, { TextColor } from 'src/components/atoms/Text';
import { Buttons, Container } from './styles';

type DataValues = {
  value: string;
  label: string;
};

export type ButtonGroupProps = {
  buttons: DataValues[];
  value: string;
  onClick?: (selected: DataValues) => void;
  label?: string;
  showLabel?: boolean;
  isDisabled?: boolean;
};

const ButtonGroup: React.FC<ButtonGroupProps> = ({
  buttons,
  value,
  onClick = () => null,
  label,
  showLabel = true,
  isDisabled = false
}: ButtonGroupProps) => {
  const handleOnClick = (selected: DataValues) => {
    const dataSelected = { value: selected.value, label: selected.label };
    onClick(dataSelected);
  };

  return (
    <Container className="ButtonGroup">
      {label && showLabel && (
        <Text color={TextColor.initial} isStretched={false}>
          {label}
        </Text>
      )}

      <Buttons>
        {buttons.map((button) => (
          <Button
            name={button.value}
            key={button.value}
            isDisabled={isDisabled}
            variant={value === button.value ? ButtonVariant.contained : ButtonVariant.ghost}
            onClick={() => handleOnClick(button)}
            size={ButtonSize.medium}
            className={value === button.value ? 'selected' : ''}
          >
            {button.label}
          </Button>
        ))}
      </Buttons>
    </Container>
  );
};

export default ButtonGroup;
