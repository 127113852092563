import styled, { css } from 'styled-components';

export const HideOnMobileCSS = css`
  @media ${({ theme }) => theme.device.mobile} {
    display: none !important;
  }
`;

const HideOnMobile = styled.div`
  ${HideOnMobileCSS}
`;

export default HideOnMobile;
