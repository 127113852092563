import styled from 'styled-components';

export const Container = styled.div`
  ol > li::marker {
    content: counters(list-item, '.') '. ';
    font-weight: bold;
  }

  li {
    margin-bottom: 1rem;
  }
`;
