import { WIZARD_BODY_MAX_WIDTH } from 'src/components/DUP/atoms/WizardBody/styles';
import { colors, redesignColors } from 'src/theme/constants';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
  text-align: center;

  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 0;
  }
`;

export const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${({ theme }) => theme.device.mobile} {
    padding-left: 1rem;
  }
`;

const BaseHeaderText = css`
  font-family: MontserratBold;
  color: #012c57;
`;

export const PropertyName = styled.div`
  ${BaseHeaderText}
  font-size: 16px;
  opacity: 0.9;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const H1 = styled.h1`
  ${BaseHeaderText}
  font-size: 40px;

  @media ${({ theme }) => theme.device.mobile} {
    font-size: 28px;
    margin: 0 1rem;
  }
`;

export const Subtitle = styled.div`
  color: ${redesignColors.gray[600]};
  font-size: 16px;
  font-weight: 600;
  margin-top: 1rem;
  max-width: ${WIZARD_BODY_MAX_WIDTH}px;

  @media ${({ theme }) => theme.device.mobile} {
    margin: 1rem;
  }
`;

export const ProgressContainer = styled.ul`
  display: flex;
  width: 100%;
  z-index: 1;
  padding-inline-start: 0;
  margin: 1.5rem 0 4rem 0;
  @media ${({ theme }) => theme.device.mobile} {
    margin: 2rem 0;
  }
`;

export const Step = styled.li<{ isActive: boolean }>`
  list-style: none;
  flex: 1;
  position: relative;
  text-align: center;
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? colors.snappt_teal : redesignColors.gray[600])};
  font-size: 14px;
  font-weight: 600;

  ${({ isActive, onClick }) => (isActive || !onClick) && `pointer-events: none;`}

  &:before {
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    border: 8px solid ${colors.gray[200]};
    border-radius: 50%;
    text-align: center;
    margin: 1px auto 12px auto;
    padding: 4px;
    background: #fff;
    outline-offset: 2px;

    &:hover {
      outline: 2px solid ${colors.gray[200]};
      outline-color: ${colors.snappt_teal};
      border-color: ${colors.snappt_teal};
    }

    ${({ isActive }) =>
      isActive &&
      `
      outline: 2px solid ${colors.gray[200]};
      outline-color: ${colors.snappt_teal};
      border-color: ${colors.snappt_teal};
    `}
  }

  &:after {
    content: '';
    position: absolute;
    width: calc(100% - 28px);
    height: 2px;
    background-color: ${colors.gray[200]};
    top: 12px;
    left: calc(-50% + 14px);
    z-index: -999;
  }

  &:first-child:after {
    content: none;
  }
`;
